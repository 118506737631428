import React, { useState } from 'react'
import LoadingButton from '../../Utils/LoadingButton'
import { Dropdown } from 'flowbite-react'

const UpdateProductQuantity = ({ on_submit, loading, data, staffMembers }) => {
  const [action, setAction] = useState('augmenter')
  const [selectedAuthor, setSelectedAuthor] = useState(null)

  return (
    <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2">
          <div className="relative w-full mb-2 pr-2">
            <label htmlFor="action" className="block mb-2 text-base font-medium text-gray-900">Action <span className="text-red-500">*</span> </label>
            <select id="action" name="action" value={action} onChange={e => setAction(e.target.value)} className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring-primary w-full ease-linear transition-all duration-150" required>
              <option value="augmenter">Entrée de stock</option>
              <option value="retirer">Sortie de stock</option>
            </select>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="relative w-full mb-2 pr-2">
            <label htmlFor="quantity" className="block mb-2 text-base font-medium text-gray-900">Quantité à {action} <span className="text-red-500">*</span> </label>
            <input type="number" name="quantity" id="quantity" defaultValue={0} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />

          </div>
        </div>
      </div>
      {/* <div className="w-full lg:w-1/2">
        <div className="relative w-full mb-2 pr-2">
          <label htmlFor="author" className="block mb-2 text-base font-medium text-gray-900">
            {`Utilisateur qui a ${action === "augmenter" ? "augmenté" : "retiré"} le stock`}
            <span className="text-red-500">*</span>
          </label>
          <Dropdown
            value={selectedAuthor}
            disabled={loading}
            onChange={(e) => setSelectedAuthor(e.value)}
            options={staffMembers}
            optionLabel="display" 
            placeholder={`Utilisateur qui a ${action === "augmenter" ? "augmenté" : "retiré"} le stock`}
            className="w-full md:w-14rem"
          />
        </div>
      </div> */}
      <LoadingButton label="Enregistrer" loading={loading} />
    </form>
  )
}

export default UpdateProductQuantity
