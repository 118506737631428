import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import { INTERNAL_ROUTES } from '../../utils/internal_routes.js';

import CardStatOne from '../../components/cards/stats/CardStatOne.js';
import Heading from '../../components/Utils/Heading.js';
import Loading from '../../components/Utils/Loading.js';
import FormModal from '../../components/modals/FormModal.js';
import { create_medical_member, get_medical_members } from './../../services/management/users.service';
import MedicalMember from '../../components/cards/MedicalMember.js';
import NewMedicalMember from '../../components/forms/users/NewMedicalMember.js';


const MedicalMemberList = () => {
    const [medical_members, setMedicalMembers] = useState([]);
    const [filtered_members, setFilteredMembers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [btn_loading, setBtnLoading] = useState(false);

    const [error, setError] = useState('');

    const [add_visible, setAddVisible] = useState(false);

    const [search, setSearch] = useState('');

    const toast = useRef(null);

    const navigate = useNavigate();


    useEffect(() => {
        const fetch_data = async () => {
            try {
                const data = await get_medical_members(1000, 0);
                setMedicalMembers(data.results || data);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetch_data();
    }, []);

    useEffect(() => {
        const filtered = medical_members.filter(member => {
            const fullName = `${member.first_name} ${member.last_name}`.toLowerCase();
            return fullName.includes(search.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [search, medical_members]);

    const handle_search_change = (event) => {
        setSearch(event.target.value);
    }

    // Operations management
    const handle_add_medical_member = async (event) => {
        event.preventDefault();
        setBtnLoading(true);
        const form_data = new FormData(event.target);
        const medical_member = Object.fromEntries(form_data.entries());
        try {
            const data = await create_medical_member(medical_member);
            console.log(data);
            setMedicalMembers([...medical_members, data.results || data]);
            console.log('OK');
            setAddVisible(false);
            setBtnLoading(false);
            handle_notification('success', 'Succès de l\'ajout', 'Nouveau membre du corps médical ajouté avec succcès!');
        } catch (e) {
            console.log(e);
            setBtnLoading(false);
            handle_notification('error', 'Erreur de l\'ajout', "Une erreur système est survenue.");
        }
    }

    const go_to_detail_page = (id) => {
        navigate(`${INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.MEDICAL_STAFF_DETAIL}/${id}`);
    }


    // Messsage management
    const handle_notification = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, life: 5000});
    }


    // Modal handler : 
    const add_medical_member_preview = () => {
        setAddVisible(true);
    }


    // Modal headers
    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }

   
    return (
        <>
            <FormModal header={modal_header('Ajouter un membre au personnel médical')} children={<NewMedicalMember on_submit={handle_add_medical_member} loading={btn_loading} />} visible={add_visible} setVisible={setAddVisible} />
            <Toast ref={toast} />

            {/* Page content */}
            <div className="p-4 lg:p-8">
            <Heading title="Liste du personnel médical" />
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Loading />
                </div>
            ) : (
                <>
                    {error ? (
                        <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
                    ) : 
                    (
                        <>
                        <div className="flex justify-between  items-center mt-6 w-full">
                            <div className="flex justify-center items-center">
                                <CardStatOne icon="mdi:doctor" title="Personnel(s) médical(aux)" value={medical_members.length} bg_color="bg-primary" />
                            </div>

                            <div className="w-full flex justify-end">
                                <div className="relative text-gray-600 w-full sm:w-3/4 md:w-1/2 lg:w-1/4">
                                    <input onChange={handle_search_change} className="border border-gray-300 bg-white h-10 px-4 rounded text-sm focus:outline-none w-full" type="search" id="filter-user" placeholder="Rechercher" />
                                    <button type="submit" className="absolute right-0 -top-2 mr-2 mt-5">
                                        <Icon icon="flowbite:search-solid" className="text-gray-500 text-xl"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                            <div className="flex justify-end items-center my-4">
                                <button onClick={() => add_medical_member_preview()} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-secondary text-white shadow">
                                    <Icon icon="mi:add" className="text-xl" />
                                    <span>Nouveau</span>
                                </button>
                            </div>
                            {medical_members.length === 0 ? (
                                <p className="text-center my-8 text-xl font-semibold"> Aucun membre du personnel médical enregistré !</p>
                            ) : 
                            (
                                <div className="w-full flex flex-wrap gap-4 lg:gap-8 items-center justify-between mt-8">
                                    {filtered_members.map((medical_member) => (
                                        <MedicalMember key={medical_member.id} on_click={go_to_detail_page} num={medical_member.id} first_name={medical_member.first_name} last_name={medical_member.last_name} roles={medical_member.groups} />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            </div>
        </>
    );
};

export default MedicalMemberList;
