import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { retrieve_intervention } from "../../services/interventions/intervention.service";
import { Toast } from "primereact/toast";
import Loading from "../../components/Utils/Loading";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import AmbulanceTrackingMap from "../../components/interventions/AmbulanceTrackingMap";
import { Icon } from '@iconify/react';
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { UserContext } from "../../context/UserContext";

const InterventionSummary = () => {

    const {intervention_id} = useParams();
    const [intervention, setIntervention] = useState(null)

    const toast = useRef(null);
    const [ready, setReady] = useState(false)

    const {user} = useContext(UserContext);

    const has_role = (required_roles) => {
      return user.groups.some(group => required_roles.includes(group.name));
    };

    
    const navigate = useNavigate();
    useEffect(() => {
        const getIntervention = async () => {
          try {
            const intervention_data = await retrieve_intervention(intervention_id);
            setIntervention(intervention_data)
            setReady(true)
          } catch (error) {
            console.log("-----error ", error)
            toast.current.show({severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000});
          }
        }
        getIntervention()
    }, [intervention_id])

    const go_to_intervention_map = async (e) => {
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_MAP}/${intervention_id}`);
    }

    const go_to_intervention_form = async (e) => {
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_FORM}/${intervention_id}`);
    }

    const statuses_fr = {
        'not-started' : "Initial",
        'team-formed' : "Equipe constituée",
        'ambulance-arrived' : "Sur le lieu d'intervention",
        'ambulance-returned' : "Retour à la base",
        'done' : "Effectuée",
        'canceled' : "Annulée",
        'white-out' : "Sortie blanche",
        'ambulance-on-going' : "En route pour l'intervention",
    }

    const statuses_styles = {
        'not-started' : "bg-gray-100 text-gray-600",
        'team-formed' : "bg-doubleLightBlue text-primary",
        'ambulance-arrived' : "bg-sky-100 text-sky-600",
        'ambulance-returned' : "bg-sky-100 text-sky-600",
        'done' : "bg-green-100 text-green-600",
        'canceled' : "bg-red-100 text-red-600",
        'white-out' : "bg-yellow-100 text-yellow-600",
        'ambulance-on-going' : "bg-sky-100 text-sky-600",
        
    }
    if (!ready) {
        return (
            <>
                <Toast ref={toast} />
                <Loading />
            </>
        )
    }
    return (
        <>
            <Toast ref={toast} />
                <p className="text-3xl text-center font-bold uppercase my-4 text-lightGreen">Intervention  {intervention_id} <span className={`px-4 py-2 rounded text-sm ml-4 ${statuses_styles[intervention.status]}`}>{statuses_fr[intervention.status]}</span></p>
                <div className="flex justify-center items-center w-full px-4 py-4 my-4 gap-8">
                <div className="map-card w-full md:w-3/12 rounded bg-white flex-col items-center justify-between py-2 px-4 shadow-lg">
                    <AmbulanceTrackingMap height="h-72"/>
                    <div className="w-full flex justify-center mt-8">
                        <button onClick={go_to_intervention_map} className="flex justify-center gap-2 bg-secondary text-white px-6 py-3 items-center rounded">
                            {has_role(['ambulance-chief', 'ambulance-member']) ? (
                                    "Aller sur la carte"
                                ):(
                                    "Suivre l'ambulance"
                            )}
                            <Icon icon={'lucide:map-pin'} className="text-xl"/>
                        </button>
                    </div>
                </div>
                <div className="map-card w-full md:w-8/12 rounded flex-col items-center justify-center">
                    <div className="mb-5">
                        <p className="text-center">{"Journée du " + format(intervention.created_at, 'EEEE d MMMM', { locale: fr }).toUpperCase()}</p>
                    </div>
                    <div className="mb-5 flex justify-center flex-wrap gap-4">
                        <input type="text" value={intervention.forms.length && (intervention.forms[intervention.forms.length - 1].patient_names)?.toUpperCase() } disabled={true} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary" />
                        <input type="text" value={intervention.forms.length && (intervention.forms[intervention.forms.length - 1].age) || ""} disabled={true} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary" />
                        <input type="text" value={intervention.forms.length && (intervention.forms[intervention.forms.length - 1].sex)?.toUpperCase()} disabled={true} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary" />
                    </div>
                    <div className="mb-5">
                        <input type="text" value={intervention.forms.length && (intervention.forms[intervention.forms.length - 1].location)?.toUpperCase()} disabled={true} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary" />
                    </div>

                    <div className="w-full flex justify-center">
                        <button onClick={go_to_intervention_form} className="flex justify-center gap-2 bg-primary text-white px-6 py-3 items-center rounded">
                            Formulaire
                            <Icon icon={'lucide:clipboard'} className="text-xl"/>
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default InterventionSummary