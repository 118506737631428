import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import HelloBanner from "../../components/cards/HelloBanner";
import CardStatThree from "../../components/cards/stats/CardStatThree";
import { NavLink, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import InterventionCard from "../../components/cards/interventions/InterventionCard";
import { Icon } from '@iconify/react';
import { Toast } from "primereact/toast";
import { create_intervention, get_user_interventions, getInterventionAmbulanceChief } from "../../services/interventions/intervention.service";
import Loading from "../../components/Utils/Loading";
import { differenceInMinutes, parseISO } from "date-fns";

/**
 * FFormatte les objets interventions pour une structure donnée et sépare en deux catégories : interventions en cours et interventions terminées ou annulées
 *
 * @param {Array} data - Le tableau d'interventions
 * @return {Object} - Un objet contenant les deux listes d'interventions
 */
function format_interventions(data) {
  const in_progress_statuses = [ "not-started", "team-formed", "ambulance-on-going", "ambulance-returned", "ambulance-arrived" ];
  const canceled_statuses = [ "white-out", "canceled" ];

  const in_progress_interventions = [];
  const other_interventions = [];

  let total = 0;
  let canceled = 0;
  let done = 0;
  let in_progress = 0;

  data.results.forEach((intervention) => {
    const { id, status, ended_at, duration, description } = intervention;

    let lead = getInterventionAmbulanceChief(intervention);

    const identifier = `#${String(id).padStart(4, "0")}`;
    const { return_at_base_datetime, location } = intervention.forms[intervention.forms.length - 1] || {};

    const transformed_intervention = {
        id,
        identifier,
        status,
        location,
        lead,
        description,
        duration,
        ended_at
    };
    console.log(intervention.forms[intervention.forms.length - 1]);    


    if (in_progress_statuses.includes(status)) {
        in_progress_interventions.push(transformed_intervention);
        in_progress++;
    } else {
        if (canceled_statuses.includes(status)) {
            canceled++;
        } else {
            done++;
        }
        other_interventions.push(transformed_intervention);
    }
  });

  // Trier les autres interventions par date de création (décroissant) et prendre les 5 premières
  other_interventions.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const reduced_other_interventions = other_interventions.slice(0, 5);

  return {
    data : {in_progress: in_progress_interventions, others: reduced_other_interventions},
    stats : {total, canceled, done, in_progress},
  };
}

const StaffDashboard = () => {
    const { user } = useContext(UserContext);
    const [interventions, setInterventions] = useState({in_progress : [], others : []});
    const [stats, setStats] = useState({total : 0, canceled : 0, done : 0, in_progress : 0});
    const [ready, setReady] = useState(false);

    const has_role = (required_roles) => {
        return user.groups.some(group => required_roles.includes(group.name));
    };

    const navigate = useNavigate();
    const toast = useRef(null);

    useEffect(() => {
        const get_staff_interventions = async () => {
            try {
                const data = await get_user_interventions();
                const formatted_data = format_interventions(data);
                setInterventions(formatted_data.data);
                setStats(formatted_data.stats);
                setReady(true);
            } catch (e) {
                console.log(e);
                toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
            }
        }

        get_staff_interventions();
    }, []);

    const handle_new_intervention = async () => {
        try {
            const data = await create_intervention();
            navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_FORM}/${data.id}`);

        } catch (e) {
            toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
            console.log(e);
        }
    }
    const go_to_intervention_detail = async (id) => {
        console.log("go_to_intervention_detail", id);
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_SUMMARY}/${id}`);
    }

    if (!ready) {

        return(
            <>
                <Toast ref={toast} />
                <Loading />
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="flex flex-wrap w-full justify-center lg:justify-between items-center lg:items-stretch gap-8 lg:gap-0">
                <div className="banner-stats flex flex-col items-center justify-center gap-8 w-full">
                    <HelloBanner name={user.first_name} />

                    <div className="w-full mx-auto flex flex-wrap items-center justify-center xl:justify-between gap-8">
                        <CardStatThree icon="mage:notification-bell-pending-fill" title="Interventions en cours" value={stats.in_progress} bg_color="bg-doubleLightBlue" color="text-secondary" card_color="bg-secondary" text_color="text-white"/>
                        <CardStatThree icon="material-symbols:today" title="Interventions du jour" value={stats.total} bg_color="bg-doubleLightBlue" color="text-secondary" />
                        <CardStatThree icon="mdi:cancel-bold" title="Sorties blanches / annulées" value={stats.canceled} bg_color="bg-red-100" color="text-red-500" />
                        <CardStatThree icon="fa:check" title="Sorties effectuées" value={stats.done} bg_color="bg-teal-100" color="text-teal-500" />
                    </div>
            
                </div>
            </div>

            {has_role(['arm']) ?
            (
                <>
                    <div className="my-8 md:my-16 w-full flex justify-center items-center">
                        <button onClick={handle_new_intervention} className="flex justify-center gap-2 bg-secondary text-white px-6 py-3 items-center rounded">
                            Nouvelle Intervention
                            <Icon icon={'ph:plus-fill'} className="text-xl"/>
                        </button>
                    </div>
                    <div className="relative dark:bg-gray-800 sm:rounded-lg w-full my-8">
                        <div className="flex flex-nowrap overflow-x-auto items-center justify-start px-4 md:px-8 lg:px-16 xl:px-32 py-4 w-full">
                            <Icon icon="material-symbols:today" className="text-gray-500 mr-4 text-xl"/>
                            <h5><span className="font-semibold text-lg xl:text-xl">Interventions en cours :</span></h5>
                        </div>
                        <div className="flex justify-center gap-8 lg:gap-16 items-center py-8">
                            {interventions.in_progress.length > 0 ? (
                                interventions.in_progress.map((i) => (
                                    <InterventionCard key={i.id} id={i.id} num={i.identifier} area={i.location} duration={i.duration} status={i.status} team_lead={i.lead} description={i.description} on_click={() => go_to_intervention_detail(i.id)}/>
                                ))
                            ):
                            (
                                <p className="text-center text-lg font-semibold text-gray-500">Aucune intervention en cours</p>
                            )}
                        </div>
                    </div>


                    <div className="relative overflow-hidden dark:bg-gray-800 sm:rounded w-full my-8 bg-white">
                        <div className="flex justify-between items-center px-4 md:px-8 lg:px-16 xl:px-32 py-4 w-full">
                            <div className="flex items-center flex-1 space-x-4">
                                <h5><span className="font-semibold text-lg xl:text-xl">Les dernières interventions :</span></h5>
                            </div>
                            <div className="flex items-center justify-end flex-1 space-x-4">
                                <NavLink to={INTERNAL_ROUTES.STAFF_BACKOFFICE + '/' + INTERNAL_ROUTES.INTERVENTIONS}>
                                    <span className="px-2 py-1 bg-secondary hover:bg-primary text-white text-xs rounded shadow">Voir plus</span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="flex justify-center gap-8 lg:gap-16 items-center py-8">
                        {interventions.others.length > 0 ? (
                                interventions.others.map((i) => (
                                    <InterventionCard key={i.id} id={i.id} num={i.identifier} area={i.location} duration={i.duration} status={i.status} team_lead={i.lead} description={i.description} on_click={() => go_to_intervention_detail(i.id)}/>
                                ))
                            ):
                            (
                                <p className="text-center text-lg font-semibold text-gray-500">Aucune intervention sur les trois derniers jours</p>
                            )
                        }
                        </div>
                    </div>
                </>
            ):
            (
                <div className="relative dark:bg-gray-800 sm:rounded-lg w-full my-8">
                    <div className="flex flex-nowrap overflow-x-auto items-center justify-start px-4 md:px-8 lg:px-16 xl:px-32 py-4 w-full">
                        <Icon icon="material-symbols:today" className="text-gray-500 mr-4 text-xl"/>
                        <h5><span className="font-semibold text-lg xl:text-xl">Vos interventions en cours :</span></h5>
                    </div>
                    <div className="flex justify-center gap-8 lg:gap-16 items-center py-8">
                    {interventions.in_progress.length > 0 ? (
                                interventions.in_progress.map((i) => (
                                    <InterventionCard key={i.id} formId={i?.forms ? i?.forms[0]?.id : null} id={i.id} num={i.identifier} area={i.location} duration={i.duration} status={i.status} team_lead={i.lead} description={i.description} on_click={() => go_to_intervention_detail(i.id)}/>
                                ))
                            ):
                            (
                                <p className="text-center text-lg font-semibold text-gray-500">Aucune intervention en cours</p>
                            )
                        }
                    </div>
                </div>
            )}

        </>
    );
}


export default StaffDashboard