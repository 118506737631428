
import { Icon } from '@iconify/react';
import { Tooltip } from 'primereact/tooltip';
import { API_BASE_URL } from '../../../services/urls';

const InterventionCard = ({id, num, area, duration, status, team_lead, description, on_click=()=>{}}) => {
    const in_progress_statuses = ['ambulance-on-going', 'ambulance-returned', 'ambulance-arrived'];
    const canceled_statuses = ['canceled', 'white-out'];
    const initial_statuses = ['not-started', 'team-formed'];

    const get_bg_color = (status) => {
        if (in_progress_statuses.includes(status)) {
            return 'bg-yellow-600';
        } else if (canceled_statuses.includes(status)) {
            return 'bg-gray-600';
        } else if (initial_statuses.includes(status)) {
            return 'bg-red-600';
        } else {
            return 'bg-primary';
        }
    }

    const get_bg_smooth_color = (status) => {
        if (in_progress_statuses.includes(status)) {
            return 'bg-yellow-100';
        } else if (canceled_statuses.includes(status)) {
            return 'bg-gray-100';
        }else if(initial_statuses.includes(status)){
            return 'bg-red-100';   
        }else {
            return 'bg-doubleLightBlue';
        }
    }

    const is_reportable = (status) => {
        if (status === "done") {
            return true;
        }
        return false;
    }

    let bg_color = get_bg_color(status);
    let bg_smooth_color = get_bg_smooth_color(status);
    let is_report = is_reportable(status);

    // const statuses_fr = [
    //     "not-started" => "non commence",
    // ]
    return (
        <>
            <Tooltip target={`#intervention-${id}`} style={{ width: '320px', backgroundColor: 'white' }}/>
            <div onClick={on_click} data-pr-tooltip={description} data-pr-position='bottom' id={`intervention-${id}`} className="rounded flex-col items-center justify-center max-w-xs cursor-pointer hover:shadow-lg">
                <div className={`card-header ${bg_color} text-white text-center font-semibold py-2 rounded-t `}>
                    {'Intervention ' + num}
                </div>
                <div className={`card-body ${bg_smooth_color} text-left p-4 min-h-48 rounded-b` }>
                    <ul className="ml-4 space-y-3 text-gray-800 list-disc list-inside">
                        <li className="mb-2 font-normal flex justify-bewteen"><span className="font-semibold mr-4">Zone:</span>{area || "Non renseignée"}</li>
                        <li className="mb-2 font-normal flex justify-bewteen items-baseline"><span className="font-semibold mr-4">Durée:</span> {duration} </li>
                        <li className="mb-2 font-normal flex justify-bewteen"><span className="font-semibold mr-4">Chef d'équipe:</span>{team_lead}</li>
                    </ul>
                    {/* <div className="flex justify-center my-2 items-center">
                        <span className="font-semibold text-gray-500 bg-white px-4 py-1 rounded-lg">{statuses_fr[status]}</span>
                    </div> */}
                    {is_report ? (
                        <div className="mt-4 flex justify-center px-4">
                            <a href={`${API_BASE_URL}/generate_pdf/${id}`} rel="noreferrer" target='_blank'>
                                <button className="flex justify-center gap-2 py-2 items-center rounded bg-transparent border border-primary w-full px-3 hover:border-0 hover:bg-primary hover:text-white">
                                    Rapport
                                    < Icon icon={'flowbite:download-solid'} className="text-xl" />
                                </button>
                            </a>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default InterventionCard
