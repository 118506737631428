import image1 from './bg-1.jpg';
import image2 from './bg-2.jpg';
import image3 from './bg-3.jpg';
import image4 from './bg-4.jpg';
import image5 from './bg-5.jpg';
import image6 from './bg-6.jpg';
import image7 from './bg-7.jpg';

const images = [image1, image2, image3, image4, image5, image6, image7];
export default images;
