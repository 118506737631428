import { useEffect, useRef, useState } from "react";
import Heading from "../../components/Utils/Heading";
import CalendarView from "../../components/planning/CalendarView";
import { Icon } from '@iconify/react';
import { add_member_to_planning, create_planning, delete_planning, get_plannings_list, remove_member_from_planning, update_planning } from "../../services/management/planning.service";
import { addDays, format, formatISO, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

import { Toast } from 'primereact/toast';
import FormModal from "../../components/modals/FormModal";
import NewPlanning from "../../components/forms/planning/NewPlanning";
import Loading from "../../components/Utils/Loading";
import { get_medical_members } from "../../services/management/users.service";


const all_roles = ["arm", "medical-regulator", "ambulance-chief", "ambulance-member", "ambulance-driver", "emergency-nurse", "rescuer", "permanent-staff"]

const format_planning = (results) => {
    return results.map(result => {
        const start_date = parseISO(result.start_date);
        const end_date = parseISO(result.end_date);
    
        const start_date_fr = format(start_date, 'dd MMMM yyyy', { locale: fr });
        const end_date_fr = format(end_date, 'dd MMMM yyyy', { locale: fr });

        let on_guard_count = 0;
        let permanent_count = 0;

        const format_members = (members, permanent=true) => {
            const formatted_members = {};
            Object.entries(members).forEach(([role, members_list]) => {
                formatted_members[role] = members_list.map(m => ({
                    id: m.member.id,
                    planning_member_id: m.id,
                    name: `${m.member.first_name} ${m.member.last_name}`,
                    function: m.member.groups.length > 0 ? m.member.groups[0].name : 'Non renseigné'
                }));
                permanent ? permanent_count+=members_list.length : on_guard_count+=members_list.length;  
            });
            all_roles.forEach(role => {
                if (!formatted_members.hasOwnProperty(role)) {
                    formatted_members[role] = [];
                }
            });
            return formatted_members;
        };
    
        const team_members_on_guard = format_members(result.members.on_guard, false);
        const team_members_permanent = format_members(result.members.permanent);
    
        return {
            id: result.id,
            title: `Planification ${result.id}`,
            start: format(start_date, 'yyyy-MM-dd'),
            end: format(addDays(new Date(end_date), 1), 'yyyy-MM-dd'),
            extendedProps: {
                start_date_fr: start_date_fr,
                end_date_fr: end_date_fr,
                members_on_guard: team_members_on_guard,
                members_permanent: team_members_permanent,
                on_guard_count: on_guard_count,
                permanent_count: permanent_count
            }
        };
    });
  };

const PlanningView = () => {

    const [plannings_list, setPlanningList] = useState([]);
    const [formatted_plannings_list, setFormattedPlanningList] = useState([]);
    const [members, setMembers] = useState([]);
    const [selected_members, setSelectedMembers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [btn_loading, setBtnLoading] = useState(false);

    const [error, setError] = useState('');
    const [dates, setDates] = useState([]);

    const [new_visible, setNewVisible] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const toast = useRef(null);


    useEffect(() => {
        const fetch_data = async () => {
            try {
                const data = await get_plannings_list(500, 0);
                handle_set_planning_list(data.results || data);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        const fetch_members = async () => {
            try {
                const data = await get_medical_members(500, 0);
                console.log('members', data);
                const filtered_members = (data.results || data).filter(member =>
                    member.groups.some(group => all_roles.includes(group.name))
                );
                setMembers(filtered_members);
            } catch (e) {
                setError(e.message);
            }
        };

        fetch_data();
        fetch_members();
        console.log('refresh', refresh)
    }, [refresh]);


    const handle_new_plannification = async (event) => {
        event.preventDefault();
        setBtnLoading(true);

        const start_date_iso = formatISO(dates[0]);
        const end_date_iso = formatISO(dates[1]);
        const planning = {
            members: selected_members,
            start_date: start_date_iso,
            end_date: end_date_iso
        }
        try {
            await create_planning(planning);
            setRefresh(prev => prev + 1);
            setBtnLoading(false);
            event.currentTarget && event.target.reset();
            setNewVisible(false);
            handle_notification('success', 'Succès de la planification', 'Planification définie avec succès');
        } catch (e) {
            console.log(e);
            setBtnLoading(false);
            handle_notification('error', 'Erreur de planification', e.message || e);
        }
    }

    const handle_planning_update = async (planning_id, update_dates) => {
        try {
            await update_planning(planning_id, update_dates);
            setRefresh(prev => prev + 1);
            handle_notification('success', 'Succès de la mise à jour', 'Les dates de cette planification ongt été mises à jour avec succès!');
        } catch (e) {
            handle_notification('error', 'Erreur de la mise à jour', e.message);
        }
    }

    const handle_planning_delete = async (planning_id) =>{
        try {
            await delete_planning(planning_id);
            const remaining_plannings = plannings_list.filter(pl => pl.id !== Number(planning_id));
            handle_set_planning_list(remaining_plannings);
            handle_notification('success', 'Succès de la suppression', 'Planification supprimée avec succès');
        } catch (e) {
            handle_notification('error', 'Erreur de la suppression', e.message);
        }
    }

    const handle_member_add = async (planning_id, selected_ids, mode) =>{
        try {
            await add_member_to_planning(selected_ids, planning_id, mode);
            setRefresh(prev => prev + 1);
            handle_notification('success', 'Succès de l\'ajout', 'Le(s) membre(s) a/ont été ajouté(s) avec succès');
        } catch (e) {
            handle_notification('error', 'Erreur de l\'ajout', e.message);
        }
    }

    const handle_member_remove = async (planning_member_id) =>{
        try {
            await remove_member_from_planning(planning_member_id);
            setRefresh(prev => prev + 1);
            handle_notification('success', 'Succès', 'Personnel retiré avec succès de l\'équipe.');
        } catch (e) {
            handle_notification('error', 'Erreur', e.message);
        }
    }

    const handle_set_planning_list = (data) => {
        setPlanningList(data);
        const formatted_data = format_planning(data);
        setFormattedPlanningList(formatted_data);
    }


    const handle_notification = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, life: 5000});
    }

    const new_planification_preview = () => {
        setNewVisible(true);
    }

    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }

    
    return (
        <>
            <FormModal header={modal_header('Définir une nouvelle planification')} width="70vw" children={<NewPlanning on_submit={handle_new_plannification} loading={btn_loading} members={members} selected_members={selected_members} setSelectedMembers={setSelectedMembers} dates={dates} setDates={setDates}  />} visible={new_visible} setVisible={setNewVisible} />

            <Toast ref={toast} />

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Loading />
                </div>
            ) : (
                <>
                    {error ? (
                        <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
                    ) : 
                    (
                    <>
                        <div className="p-4 lg:p-8">
                        <Heading title="Planification" />

                        <div className="flex justify-end items-center my-4">
                            <button onClick={new_planification_preview} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-secondary text-white shadow">
                                <Icon icon="mi:add" className="text-xl" />
                                <span>Nouvelle planification</span>
                            </button>
                        </div>

                        <CalendarView plannings={formatted_plannings_list} remove_member={handle_member_remove} add_member={handle_member_add} update_planning={handle_planning_update} delete_planning={handle_planning_delete} all_members={members} />
                        </div>
                    </>
                    )}
                </>
            )}
            
        </>
    );
};

export default PlanningView;