import { PLANNING_URL, PLANNING_MEMBERS_URL, BULK_PLANNING_MEMBERS_URL } from "../urls";
import axios from './../customAxios';
import { formatISO, startOfMonth, endOfMonth, addMonths } from 'date-fns';

export const get_plannings_list = async (limit, offset) => {
    const from_date = formatISO(startOfMonth(new Date()));
    const to_date = formatISO(endOfMonth(addMonths(new Date(), 6)));

    try {
        const response = await axios.get(PLANNING_URL, {
            params: {
                from_date: from_date,
                to_date: to_date,
                limit: limit,
                offset: offset
            }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const get_planning_members = async (planning_id) => {
    try {
        const response = await axios.get(PLANNING_MEMBERS_URL, {
            params: {
                planning_id: planning_id,
                limit: 1000000,
                offset: 0
            }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const create_planning = async (data) => {
    try {
        await axios.post(PLANNING_URL, {
            'start_date': data.start_date,
            'end_date': data.end_date,
        });

        return ;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_planning = async (planning_id, dates) => {
    try {
        const response = await axios.put(`${PLANNING_URL}${planning_id}/`, {
            'start_date': dates[0],
            'end_date': dates[1]
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const delete_planning = async (planning_id) => {
    try {
        const response = await axios.delete(`${PLANNING_URL}${planning_id}/`, {
            params: {
                id: planning_id
            }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const add_member_to_planning = async (members_id, planning_id, mode) => {
    try {
        const data = members_id.map(member_id => {
            return { 'member': member_id, 'planning': planning_id, 'is_permanent': mode === 'permanent' };
        });
        const response = await axios.post(BULK_PLANNING_MEMBERS_URL, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const remove_member_from_planning = async (planning_member_id) => {
    try {
        const response = await axios.delete(`${PLANNING_MEMBERS_URL}${planning_member_id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const get_week_planning_members = async () => {
    try {
        // const start_of_week = formatISO(startOfWeek(new Date()));
        const start = formatISO(startOfMonth(new Date()));
        const end = formatISO(endOfMonth(new Date()));

        // console.log(start_of_week, end_of_week);

        const response = await axios.get(PLANNING_URL, {
            params: { 
                from_date: start,
                to_date: end,
                limit: 1000000,
                offset: 0
            }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
