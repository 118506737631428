// import PropTypes from 'prop-types';

import { useState } from "react";

const CustomRadioSet = ({data}) => {
    const [selected_value, setSelectedValue] = useState(data.records[data.default].value);

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };
    return (
        <>
            <span className="text-gray-900 font-medium">{data.label}</span>
            <div className="w-full flex justify-between gap-4 items-center">
                {data.records.map((record, index) => (
                    <div key={index} className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 flex-auto">
                    <input
                        checked={selected_value === record.value}
                        onChange={handleChange}
                        id={`custom-set-radio-${index}`}
                        type="radio"
                        value={record.value}
                        name={data.name}
                        className="w-4 h-4 text-primary border-gray-300 focus:ring-primary focus:ring-2"
                    />
                    <label
                        htmlFor={`custom-set-radio-${index}`}
                        className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        {record.label}
                    </label>
                    </div>
                ))}
            </div>
        </>
  );
}

// CustomRadioSet.propTypes = {
//   data: PropTypes.shape({
//     label: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//     records: PropTypes.arrayOf(
//       PropTypes.shape({
//         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//         label: PropTypes.string.isRequired
//       })
//     ).isRequired
//   }).isRequired
// };

export default CustomRadioSet