import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const AdminDashboard = () => {
    const { user } = useContext(UserContext);
    return (
        <>
            <div className="my-16 text-center text-xl">
                {user.first_name} {user.last_name}
            </div>
        </>
    );
}


export default AdminDashboard