import LoadingButton from '../../Utils/LoadingButton';
import { Label, Radio } from 'flowbite-react';


const roles_fr = {
    "arm": "Assistant de régulation médicale",
    "ambulance-member": "Membre d'équipe d'intervention",
    "emergency-nurse": "Urgentiste",
    "ambulance-driver": "Chauffeur d'ambulance",
    "ambulance-chief": "Chef d'ambulance",
    "medical-regulator": "Médecin régulateur",
    "chief-supervisor": "Surveillant",
    "admin": "Directeur général",
    "rescuer" : "Secouriste",
};


const UpdateMedicalMember = ({on_submit, loading, data}) => {
    return (
        <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <label htmlFor="last_name" className="block mb-2 text-base font-medium text-gray-900">Nom <span className="text-red-500">*</span> </label>
                        <input type="text" name="lastname" id="last_name" defaultValue={data.last_name} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <label htmlFor="first_name" className="block mb-2 text-base font-medium text-gray-900">Prénom(s) <span className="text-red-500">*</span> </label>
                        <input type="text" name="firstname" id="first_name" defaultValue={data.first_name} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <div className="relative w-full mb-2 pr-2">
                            <label htmlFor="username" className="block mb-2 text-base font-medium text-gray-900">Nom d'utilisateur <span className="text-red-500">*</span> </label>
                            <input type="text" name="username" id="username" defaultValue={data.username} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <label htmlFor="email" className="block mb-2 text-base font-medium text-gray-900">Adresse e-mail <span className="text-red-500">*</span> </label>
                        <input type="email" name="email" id="email" defaultValue={data.email} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
                    </div>
                </div>
            </div>
                
            <div>
                <div className="relative w-full mb-2 pr-2">
                    <label htmlFor="group" className="block mb-2 text-base font-medium text-gray-900">
                        Fonction <span className="text-red-500">*</span>
                    </label>
                    <select
                        id="group"
                        name="group"
                        className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring-primary w-full ease-linear transition-all duration-150"
                        defaultValue={data.groups[0].name}
                        required
                    >
                        {Object.entries(roles_fr).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex items-center gap-2">
                <Radio id="radio-1" name="is_active" value="1" checked={data.is_active} />
                <Label htmlFor="radio-1">Rendre l'utilisateur actif</Label>
            </div>
            <div className="flex items-center gap-2">
                <Radio id="radio-2" name="is_active" value="0" checked={!data.is_active} />
                <Label htmlFor="radio-2">Rendre l'utilisateur inactif</Label>
            </div>
                        

            <LoadingButton label="Mettre à jour" loading= {loading} />
        </form>
    )
}


export default UpdateMedicalMember