import { useEffect, useRef, useState } from "react";
import { Icon } from '@iconify/react';
import { get_kpi_data } from "../../../services/stats.service";
import { Toast } from 'primereact/toast';
import Heading from './../../../components/Utils/Heading';
import FormModal from "../../../components/modals/FormModal";
import DateFilter from "../../../components/forms/DateFilter";

import "../../../assets/css/stats.css";

const KPI = () => {
    const [kpi_data, setKpiData] = useState({
        average_interventions_response_time_seconds : 0,
        percent_interventions_canceled : 0,
        percent_interventions_done : 0,
        percent_interventions_white_out : 0,
        total_interventions : 0,
        total_interventions_canceled : 0,
        total_interventions_done : 0,
        total_interventions_on_going : 0,
        total_interventions_white_out : 0
    });

    const [filter_start_date, setFilterStartDate] = useState(null);
    const [filter_end_date, setFilterEndDate] = useState(null);

    const [filter_visible, setFilterVisible] = useState(false);
    const [btn_loading, setBtnLoading] = useState(false);


    const toast = useRef(null)
    
    useEffect(() => {
        const get_stats = async () => {
            try {
                const data = await get_kpi_data();
                setKpiData(data)
                console.log(data)
            } catch (e) {
                toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
            }
        }
        get_stats();
    }, []);
    
    const get_period_stats = async (event) => {
        event.preventDefault();
        setBtnLoading(true);
        try {
            alert("En cours d'implémentation");
            const param = {
                start_date: filter_start_date,
                end_date: filter_end_date
            }
            console.log(param)
            setFilterVisible(false);
            setBtnLoading(false);
            toast.current.show({severity: 'success', summary: 'Succès', detail: 'Statistiques récupérées avec succès.', life: 5000});
        } catch (e) {
            toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
        }
    }
    
    const show_filter_modal = () => {
        setFilterVisible(true);
    };


    return (
        <>
            <Toast ref={toast} />
            <Heading title="Principaux indicateurs de performance" />
            <FormModal header={"Filtrer"} visible={filter_visible} setVisible={setFilterVisible} children={<DateFilter on_submit={get_period_stats} loading={btn_loading} set_start_date={setFilterStartDate} set_end_date={setFilterEndDate} type="KPI principaux"/>}/>
            
            <div className="w-full flex justify-end items-center px-4 md:px-8">
                <button onClick={show_filter_modal} className="flex gap-2 justify-center items-center px-4 py-1.5 bg-black hover:bg-primary rounded text-white"><Icon icon={'fa:filter'} className="text-xl" /> <span>Filtrer</span></button>
            </div>

            <div className="flex flex-col gap-8 xl:gap-16 items-center justify-center px-4 md:px-8 py-8">
                <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-secondary rounded-xl shadow-lg duration-300 hover:shadow-2xl">
                    <div className="flex flex-row justify-between items-center">
                        <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                            <Icon icon={'fa:hourglass-2'} className="text-gray-50 p-1 text-3xl font-semibold" />
                        </div>
                    </div>
                    <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold mt-8 mb-2 text-gray-50">{kpi_data.total_interventions}</h1>
                    <p className="text-gray-200">Nombre total d'interventions</p>
                </div>

                <div className="stat-cards flex flex-wrap justify-between items-center gap-8">
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'fa:hourglass-2'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{kpi_data.total_interventions_on_going}</h1>
                        <p className="group-hover:text-gray-200">Interventions en cours</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'mage:phone-cancel-fill'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{kpi_data.total_interventions_canceled}</h1>
                        <p className="group-hover:text-gray-200">Appels décommandés</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'fa:ban'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{kpi_data.total_interventions_white_out}</h1>
                        <p className="group-hover:text-gray-200">Sorties blanches</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'eva:done-all-outline'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{kpi_data.total_interventions_done}</h1>
                        <p className="group-hover:text-gray-200">Interventions effectuées</p>
                    </div>
                    
                </div>

                <div className="stat-cards flex flex-wrap justify-between items-center gap-8">
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'material-symbols:time-auto'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{(kpi_data.average_interventions_response_time_seconds / 60).toFixed(2)}</h1>
                        <p className="group-hover:text-gray-200">Temps moyen de réponse (min)</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'ic:round-percent'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{(kpi_data.percent_interventions_canceled * 100).toFixed(2)}</h1>
                        <p className="group-hover:text-gray-200">Taux d'appels décommandés</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'ic:round-percent'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{(kpi_data.percent_interventions_white_out * 100).toFixed(2)}</h1>
                        <p className="group-hover:text-gray-200">Taux de sorties blanches</p>
                    </div>
                    <div className="w-80 single-stat-card cursor-pointer flex flex-col px-10 py-6 overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
                        <div className="flex flex-row justify-between items-center">
                            <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                                <Icon icon={'ic:round-percent'} className="group-hover:text-gray-50 p-1 text-3xl font-semibold text-secondary" />
                            </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl xl:text-3xl font-bold text-secondary mt-8 mb-2 group-hover:text-gray-50">{(kpi_data.percent_interventions_done * 100).toFixed(2)}</h1>
                        <p className="group-hover:text-gray-200">Taux d'interventions effectuées</p>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default KPI