import LoadingButton from './../Utils/LoadingButton';

const DateFilter = ({on_submit, loading, set_start_date, set_end_date, type}) =>{
    return (
        <>
            <div className="flex flex-col gap-8 items-center justify-center w-full">
                <div className="date-filter w-full mx-auto text-center">
                    <span className="mb-4">Entrer une date de début et de fin pour obtenir les {type} sur cette période.</span>
                    <form onSubmit={on_submit} className="flex flex-col gap-4" method="post">

                        <div className="flex items-center justify-center mt-4">
                            <div className="relative">
                                <input onChange={(e) => set_start_date(e.target.value)} name="start_date" type="date" className="bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Date de début" />
                            </div>
                            <span className="mx-4 text-gray-500">à</span>
                            <div className="relative">
                                <input onChange={(e) => set_end_date(e.target.value)} name="end_date" type="date" className="bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Date de fin" />
                            </div>
                        </div>
                        <div className="btn-container flex items-center justify-center">
                            <LoadingButton label="Obtenir" loading={loading} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DateFilter