import { Dialog } from 'primereact/dialog';

const FormModal = ({header, children, visible, setVisible, width='60vw'}) => {

    return (
        <Dialog header={header} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}
            style={{ width: width }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
            <div className="w-full p-4 my-4 flex items-center justify-center">
                {children}
            </div>
        </Dialog>
    )
}

export default FormModal
        