import { InputTextarea } from 'primereact/inputtextarea';

const CustomInputTextarea = ({ ...props }) => {
    return (
        <InputTextarea
            {...props}
            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    );
}

export default CustomInputTextarea