import { Icon } from '@iconify/react/dist/iconify.js';
import logo from '../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { useContext } from 'react';

const MobileStaffNavbar = () => {

    const { user } = useContext(UserContext);
    const has_role = (required_roles) => {
        return user.groups.some(group => required_roles.includes(group.name));
    };

    return (
        <nav className="bg-white shadow">
            <div className="py-4 px-6 w-full flex xl:hidden justify-between items-center bg-white fixed top-0 z-40 shadow">
                <div aria-label="logo" role="img" tabIndex="0" className="focus:outline-none w-24">
                    <NavLink to="/app/personnel-medical"  className="flex items-center justify-between mr-4">
                        <img className="w-8 sm:w-10 md:w-12" src={logo} alt="Logo SAMU BENIN" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ml-2">SAMU BENIN</span>
                    </NavLink>
                </div>
                <div className="flex items-center">
                    <button id="menu" aria-label="open menu" className="focus:outline-none focus:ring-2 focus:ring-gray-600 rounded-md text-gray-800">
                        <Icon icon="gravity-ui:bars" className="xl:text-lg" />
                    </button>
                </div>
            </div>

            <div className="absolute w-full h-full transform -translate-x-full z-40 xl:hidden" id="mobile-nav">
            <div className="bg-gray-800 opacity-50 w-full h-full"></div>
            <div className="w-64 z-40 fixed overflow-y-auto top-0 bg-white shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out">
                <div className="px-6 h-full">
                <div className="flex flex-col justify-between h-full w-full">
                    <div>
                    <div className="mt-6 flex w-full items-center justify-between">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center">
                                <NavLink to="/app/personnel-medical"  className="flex items-center justify-between mr-4">
                                    <img className="w-8 sm:w-10 md:w-12" src={logo} alt="Logo SAMU BENIN" />
                                    <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ml-2">SAMU BENIN</span>
                                </NavLink>
                            </div>
                            <button id="cross" aria-label="close menu" className="focus:outline-none focus:ring-2 rounded-md text-gray-800">
                                <Icon icon="gravity-ui:xmark" className="text-2xl" />
                            </button>
                        </div>
                    </div>
                        <ul className="f-m-m">
                            <li>
                                <div className="text-gray-800 pt-10 flex items-center">
                                    <Icon icon="mdi:doctor" className="text-gray-500 xl:text-lg" />
                                    <span className="ml-2 font-bold">
                                        <NavLink to="/app/personnel-medical" className={({ isActive }) => isActive ? "bg-secondary text-white font-semibold" : ""}>
                                            Accueil
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div className="text-gray-800 pt-10 flex items-center">
                                    <Icon icon="ph:siren-fill" className="text-gray-500 xl:text-lg" />
                                    <span className="ml-2 font-bold">
                                        <NavLink to="/app/interventions" className={({ isActive }) => isActive ? "bg-secondary text-white font-semibold" : ""}>
                                            Interventions
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            {has_role(['arm']) && (
                            <li>
                                <div className="text-gray-800 pt-10 flex items-center">
                                    <Icon icon="mdi:doctor" className="text-gray-500 xl:text-lg" />
                                    <span className="ml-2 font-bold">
                                        <NavLink to="/app/equipes" className={({ isActive }) => isActive ? "bg-secondary text-white font-semibold" : ""}>
                                            Equipes
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            )}
                            <li>
                                <div className="text-gray-800 pt-10 flex items-center">
                                    <Icon  icon="fluent:patient-20-filled" className="text-gray-500 xl:text-lg" />
                                    <span className="ml-2 font-bold">
                                        <NavLink to="/app/patients" className={({ isActive }) => isActive ? "bg-secondary text-white font-semibold" : ""}>
                                            Patients
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            {has_role(['arm']) && (
                            <li>
                                <div className="text-gray-800 pt-10 flex items-center">
                                    <Icon icon="solar:library-bold-duotone" className="text-gray-500 xl:text-lg" />
                                    <span className="ml-2 font-bold">
                                        <NavLink to="/app/base-de-connaissance" className={({ isActive }) => isActive ? "bg-secondary text-white font-semibold" : ""}>
                                            Bibliothèque
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            )}
                        </ul>
                    </div>
                    
                </div>
                </div>
            </div>
            </div>
        </nav>
    )
}

export default MobileStaffNavbar