import { PRODUCTS_URL, STOCK_MOVEMENT_URL } from "../urls";
import axios from './../customAxios';

export const get_products = async (limit, offset, othersParams={}) => {
    try {
        const response = await axios.get(PRODUCTS_URL, {
        params: {
            limit: limit,
            offset: offset,
            ...othersParams
        }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const get_product = async (product_id) =>{
    try {
        const response = await axios.get(`${PRODUCTS_URL}${product_id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const create_product = async (data) => {
    try {
        const response = await axios.post(PRODUCTS_URL, {
            'name': data.name,
            'description': data.description,
            'product_type': data.product_type
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const increase_stock = async (data, id) => {
    try {
        const response = await axios.post(`${STOCK_MOVEMENT_URL}`, {
            'action': "supply",
            'quantity': data.quantity,
            'product': id
        });
        if (response.data.error) return handle_error(response.data.error)
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const decrease_stock = async (data, id) => {
    try {
        const response = await axios.post(`${STOCK_MOVEMENT_URL}`, {
            'action': "use",
            'quantity': data.quantity,
            'product': id,
            //'intervention': data.intervention
        });
        if (response.data.error) return handle_error(response.data.error)
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_product = async (updated_data, id) => {
    try {
        const response = await axios.put(`${PRODUCTS_URL}${id}/`, {
            'name': updated_data.name,
            'description': updated_data.description,
            'product_type': updated_data.product_type
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
  }
};

export const delete_product = async (id) => {
    try {
        const response = await axios.delete(`${PRODUCTS_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const get_stock_movements = async (id, limit, offset, othersParams={}) =>{
    try {
        const response = await axios.get(`${STOCK_MOVEMENT_URL}?product=${id}`, {
            params: {
                limit: limit,
                offset: offset,
                ...othersParams
            }
        });
        // if (response.data.error) return handle_error(response.data.error)
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};