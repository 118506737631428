import { BULK_INTERVENTION_TEAM_MEMBER_URL } from "../urls";
import axios from '../customAxios';


export const bulk_create_intervention_member = async (data) => {
    try {
        const response = await axios.post(BULK_INTERVENTION_TEAM_MEMBER_URL, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const bulk_delete_intervention_member = async (data) => {
    try {
        const response = await axios.delete(BULK_INTERVENTION_TEAM_MEMBER_URL, {
            data
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
