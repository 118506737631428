import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Label } from "flowbite-react";
import { create_intervention_form, get_intervention_forms, update_intervention_form } from '../../services/management/intervention_form.service';
import { get_intervention_reasons } from '../../services/management/intervention_reason.service';
import InterventionAmbulanceManager from './InterventionAmbulanceManager';
import { Toast } from 'primereact/toast';
import { retrieve_intervention, update_intervention_status } from '../../services/interventions/intervention.service';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_ROUTES } from './../../utils/internal_routes';
import CustomInputTextarea from './../../components/forms/inputs/CustomInputTextarea';
import CustomInputText from '../../components/forms/inputs/CustomInputText';
import CustomInputNumber from '../../components/forms/inputs/CustomInputNumber';
import CustomDropdown from './../../components/forms/inputs/CustomDropdown';
import CustomMultiSelect from '../../components/forms/inputs/CustomMultiSelect';
import { UserContext } from '../../context/UserContext';
import Dictaphone from '../../components/Utils/Dictaphone';
import BooleanInput from '../../components/Utils/BooleanInput';
import { createAuthWebSocket } from '../../services/auth.service';
import InterventionAskMedRegHelpModal from './InterventionAskMedRegHelpModal';
import { InterventionStatus } from '../common/constants';


const FORM_AUTO_UPDATE_INTERVAL = 1500

const FormField = (props) => {
  const {Component, componentProps, placeholder, ...otherProps} = props

  return (
    <div className="mb-5 mx-5">
      <div className="mb-2 block pl-0 ml-0">
        <Label value={placeholder} />
      </div>
      <Component
        {...componentProps}
        {...otherProps}
        className="w-full"
      />
    </div>
  )
}

// FIXME: Move this to utils
const getLastNumberInText = (text) => {
  text = String(text);
  const matches = text.match(/\d+/g);
  if (!matches) {
    return "";
  }
  return matches[matches.length - 1];
}

const FieldsGroupDivider = ({name}) => (
  <div className="relative flex py-5 items-center">
    <div className="flex-grow border-t border-gray-300"></div>
    <span className="flex-shrink mx-4 text-gray-500">{name}</span>
    <div className="flex-grow border-t border-gray-300"></div>
  </div>
)

const InterventionFormEditor = ({ interventionId }) => {
  const [data, setData] = useState(null)
  const [showAskMedRegHelpModal, setShowAskMedRegHelpModal] = useState(false)
  const [lastSavedData, setLastSavedData] = useState(null)
  const [intervention, setIntervention] = useState(null)
  const [interventionReasons, setInterventionReasons] = useState([])
  const [loading, setLoading] = useState(false)
  const [stateIsReady, setStateIsReady] = useState(false)
  const [focusedFieldName, setFocusedFieldName] = useState(null)
  const numbersFields = [
    "age",
    "hemodynamic_pulse",
    "hemodynamic_weight",
    "hemodynamic_t",
    "vital_param_temperature",
  ]

  const stepperRef = useRef(null);
  const toast = useRef(null);

  const {user} = useContext(UserContext);

  const has_role = (required_roles) => {
    return user.groups.some(group => required_roles.includes(group.name));
  };


  /** Socket management start */
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!lastSavedData) return

    const newSocket = createAuthWebSocket({
      resource: "intervention_forms",
      roomName: data.id
    })

    newSocket.onopen = () => {
      //console.log('WebSocket connected');
    };

    newSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      let _data = JSON.parse(JSON.stringify(data))
      for (let fieldName of Object.keys(message.data)) {
        let value = message.data[fieldName]
        if (value === "true" || value === "false") value = Boolean(value)
        _data[fieldName] = message.data[fieldName]
      }
      setData(_data)
    };

    newSocket.onclose = () => {
      // console.log('WebSocket disconnected');
    };

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [lastSavedData]);

  useEffect(() => {
    if (!(data && socket)) return
  
    const interval = setInterval(() => {
      const _changedData = {}
      const _data = JSON.parse(JSON.stringify(data))
      delete _data.id
      delete _data.intervention
      delete _data.author
      for (let field of Object.keys(_data)) {
        if (_data[field] !== null && String(_data[field]) !== String(lastSavedData[field])) {
          _changedData[field] = _data[field]
        }
      }

      // If there are changes, we push them and update `lastSavedData`
      if (Object.keys(_changedData).length) {
        setLastSavedData({...data})
        socket.send(JSON.stringify(_changedData));
      }
    }, FORM_AUTO_UPDATE_INTERVAL);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [data, socket]);
  /** Socket management end */

  const navigate = useNavigate();

  useEffect(() => {
    const setForm = async () => {
      setLoading(true);
      try {
        const response = await get_intervention_forms({intervention: interventionId});
        // If there is at least one form, use it otherwise, create it.
        if (response.results.length) {
          setData(response.results[0])
          setLastSavedData(response.results[0])
        } else {
          const _form = await create_intervention_form({intervention: interventionId});
          setData(_form)
          setLastSavedData(_form)
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
      }
    };
    const setInterventionReasonsData = async () => {
      setLoading(true);
      try {
        const response = await get_intervention_reasons();
        setInterventionReasons(response.results)
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue lors de la récupération des données.", life: 5000});
      }
    };
    setForm();
    setInterventionReasonsData();
  }, []);

  useEffect(() => {
    if (interventionReasons?.length && data && intervention) setStateIsReady(true)
  }, [data, interventionReasons, intervention])

  useEffect(() => {
    if (intervention) return

    const getIntervention = async () => {
      try {
        const intervention_data = await retrieve_intervention(interventionId);
        setIntervention(intervention_data)
      } catch (error) {
        console.log("-----error ", error)
        toast.current && toast.current.show({severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000});
      }
    }
    getIntervention()
  }, [data, interventionId])

  const launch_intervention = async () => {
    try {
      const condition_ok = intervention.ambulances.length > 0 && intervention.ambulances[0].ambulance && intervention.ambulances[0].staff_members.length > 0
      if(!condition_ok) {
        toast.current.show({severity: 'warning', summary: 'Attention', detail: 'Veuillez affecter au moins une équipe et une ambulance pour cette intervention.', life: 5000});
      }
      await update_intervention_status(interventionId, "team-formed");
      follow_intervention();
      
    } catch (e) {
      toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue lors du lancement de l'intervention.", life: 5000});
    }
  };

  const follow_intervention = () => {
    try {
      navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_MAP}/${interventionId}`);
    } catch (e) {
      toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Il n'est pas possible de suivre l'ambulance. Une erreur est survenue.", life: 5000});
    }
  };

  const updateFormField = (fieldName, value)  => {
    if (numbersFields.includes(fieldName)) value = getLastNumberInText(value)
    let _data = {...data}
    // console.log("-------updateFormField ", value)
    _data[fieldName] = value
    setData(_data)
  }

  const getFieldProps = (field) => {
    let _props = {...field}
    if (field.Component === BooleanInput) {
      _props.checked = data[field.key]
    } else {
      _props.value = data[field.key]
    }
    _props.onChange = (e) => updateFormField(field.key, e?.target?.value || e?.value)
    if ([CustomInputText, CustomInputTextarea, CustomInputNumber].includes(field.Component)) {
      _props.onClick = () => {
        if (field.key !== focusedFieldName) {
          setFocusedFieldName(field.key)
        }
      }
    }
    return _props
  }

  /** Define first form fields */
  const baseInfoFields = [
    {
      key: "reasons",
      placeholder: "Raison(s) de l'intervention",
      Component: CustomMultiSelect,
      componentProps: {options: interventionReasons, optionLabel: "name"}
    },
  ]
  const patientPersonalInfoFields = [
    {
      key: "patient_names",
      placeholder: "Nom et prénoms du patient",
      Component: CustomInputText,
    },
    {
      key: "sex",
      placeholder: "Sexe du patient",
      Component: CustomDropdown,
      componentProps: {
        options: [
          {name: 'Homme', value: 'male'},
          {name: 'Femme', value: 'female'},
          {name: 'Autre', value: 'other'}
        ],
        optionLabel: "name"
      }
    },
    {
      key: "age",
      placeholder: "Age du patient",
      Component: CustomInputNumber,
    },
  ]
  const patientHemodynamicFields = [
    {
      key: "hemodynamic_ta",
      placeholder: "TA",
      Component: CustomInputText,
    },
    {
      key: "hemodynamic_pulse",
      placeholder: "Pouls",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_t",
      placeholder: "T°",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_coloration",
      placeholder: "Coloration",
      Component: CustomInputText,
    },
    {
      key: "hemodynamic_weight",
      placeholder: "Poids",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_others",
      placeholder: "Autres",
      Component: CustomInputTextarea,
      componentProps: {rows: 5}
    },
  ]
  const patientHealthFields = [
    {
      key: "patient_is_conscious",
      placeholder: "Le patient est-il conscient ?",
      Component: BooleanInput,
    },
    {
      key: "patient_is_breathing",
      placeholder: "Le patient respire t-il ?",
      Component: BooleanInput,
    },
  ]
  const interventionLocationFields = [
    {
      key: "location",
      placeholder: "Lieu de l'intervention",
      Component: CustomInputText,
    },
    {
      key: "geo_location",
      placeholder: "Situation geographique ou plan",
      Component: CustomInputText,
    },
  ]
  const closeRelativeFields = [
    {
      key: "close_relative_names",
      placeholder: "Nom-prénoms du parent proche du / patient ou de la patiente",
      Component: CustomInputText,
    },
    {
      key: "close_relative_contact",
      placeholder: "Numéro du parent proche du / patient ou de la patiente",
      Component: CustomInputText,
    },
    {
      key: "form_retriever_names",
      placeholder: "Fiche d'intervention retirée par",
      Component: CustomInputText,
    },
  ]

  /** Define second form fields */
  const patientTreatmentsFields = [
    {
      key: "brief_clinical_exam",
      placeholder: "Examen clinique sommaire",
      Component: CustomInputTextarea,
      componentProps: {rows: 5}
    },
    {
      key: "treatments_performed",
      placeholder: "Soins administrés",
      Component: CustomInputTextarea,
      componentProps: {rows: 5}
    },
    {
      key: "events_occured_during_trip",
      placeholder: "Evénements en cours de trajet/observations",
      Component: CustomInputTextarea,
      componentProps: {rows: 5}
    },
    {
      key: "diagnosis",
      placeholder: "Diagnostic retenu",
      Component: CustomInputTextarea,
    },
    {
      key: "recovery_of_intervention_costs",
      placeholder: "Recouvrement des frais d'intervention ?",
      Component: BooleanInput,
    },
  ]
  const patientVitalParamsFields = [
    {
      key: "vital_param_pa",
      placeholder: "PA",
      Component: CustomInputText,
    },
    {
      key: "vital_param_fc",
      placeholder: "FC",
      Component: CustomInputText,
    },
    {
      key: "vital_param_fr",
      placeholder: "FR",
      Component: CustomInputText,
    },
    {
      key: "vital_param_sao2",
      placeholder: "Sa02",
      Component: CustomInputText,
    },
    {
      key: "vital_param_glasgow_score",
      placeholder: "Score Glasgow",
      Component: CustomInputText,
    },
    {
      key: "vital_param_temperature",
      placeholder: "Température",
      Component: CustomInputNumber,
    },
  ]
  const patientMedicalGesturesFields = [
    {
      key: "medical_gesture_o2",
      placeholder: "0₂",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_iot",
      placeholder: "IOT",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_guedei",
      placeholder: "Guedel",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_vvp",
      placeholder: "WVP",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_sng",
      placeholder: "SNG",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_sv",
      placeholder: "SV",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_ml",
      placeholder: "ML",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_mce",
      placeholder: "MCE",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_debrifiler",
      placeholder: "Défibrillateur",
      Component: BooleanInput,
    },
  ]
  const relatedDoctorFields = [
    {
      key: "syndromic_summary",
      placeholder: "Résumé syndromique (Par le médecin)",
      Component: CustomInputTextarea,
    },
  ]
  const patientHospitalizationFields = [
    {
      key: "patient_hospitalized",
      placeholder: "Le patient a-t-il été hospitalisé ?",
      Component: BooleanInput,
    },
    {
      key: "hospitalization_hospital",
      placeholder: "Hôpital",
      Component: CustomInputText,
    },
    {
      key: "hospitalization_service",
      placeholder: "Service",
      Component: CustomInputText,
    },
    {
      key: "patient_went_to_reha",
      placeholder: "Le patient a-t-il été en réhanimation ?",
      Component: BooleanInput,
    },
    {
      key: "reha_place",
      placeholder: "Si oui, Où ?",
      Component: CustomInputText,
    },
    {
      key: "patient_evacuated",
      placeholder: "Le patient a-t-il été evacué ?",
      Component: BooleanInput,
    },
  ]
  const patientLifeForecastJ1Fields = [
    {
      key: "patient_alive_j1",
      Component: BooleanInput,
      placeholder: "Vivant ou Décédé ?",
      componentProps: {trueText: "Vivant", falseText: "Décédé"}
    },
    {
      key: "patient_alive_j1_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]
  const patientLifeForecastJ5Fields = [
    {
      key: "patient_alive_j5",
      Component: BooleanInput,
      placeholder: "Vivant ou Décédé ?",
      componentProps: {trueText: "Vivant", falseText: "Décédé"},
    },
    {
      key: "patient_alive_j5_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]
  const patientLifeForecastJ10Fields = [
    {
      key: "patient_alive_j10",
      placeholder: "Vivant ou Décédé ?",
      Component: BooleanInput,
      componentProps: {trueText: "Vivant", falseText: "Décédé"},
    },
    {
      key: "patient_alive_j10_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]

  if (!stateIsReady) return <ProgressSpinner />

  return (
    <div className="relative">
      <Toast ref={toast} />
      <div className="flex justify-between py-2 mb-4 items-center">
        <div className=' w-full md:w-2/3 xl:w-1/2'>
          <h1 className="uppercase text-lg xl:text-xl">Formulaire de renseignement pour assistance médicale</h1>
        </div>
        {intervention.status === "not-started" ? (
          has_role(['arm']) ?
            (<Button
              className="bg-primary text-white px-4 py-2"
              label="Lancer l'intervention"
              onClick={launch_intervention}
            />
          ):null
        ):
        (
          <Button
            label="Suivre l'ambulance"
            link
            onClick={follow_intervention}
          />
        )
      }
      </div>
      <Stepper ref={stepperRef}>
        {/** First part */}
        <StepperPanel header="Partie 1">
          <Card>
            <FieldsGroupDivider name="Informations génériques"/>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
              {[...baseInfoFields, ...patientPersonalInfoFields].map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Hémodynamique"/>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              {patientHemodynamicFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Etat de santé du patient"/>
            <div className="grid grid-cols-6 md:grid-cols-3 lg:grid-cols-4">
              {patientHealthFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Autres"/>
            <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-2">
              {[...interventionLocationFields, ...closeRelativeFields].map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>
          </Card>
        </StepperPanel>
        {/** Second part */}
        <StepperPanel header="Partie 2">
          <Card>
            <FieldsGroupDivider name="Soins éffectues"/>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {patientTreatmentsFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Paramètres vitaux"/>
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">
              {patientVitalParamsFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Gestes Techniques SAMU"/>
            <div className="grid grid-cols-6 md:grid-cols-3 lg:grid-cols-6">
              {patientMedicalGesturesFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Par le médecin"/>
            <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-2">
              {relatedDoctorFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Hospitalisation"/>
            <div className="grid grid-cols-6 md:grid-cols-3 lg:grid-cols-3">
              {patientHospitalizationFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J1"/>
            <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ1Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J5"/>
            <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ5Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J10"/>
            <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ10Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>
          </Card>
        </StepperPanel>
        {/** Second part */}
        <StepperPanel header="Affectation équipe">
          <InterventionAmbulanceManager interventionId={interventionId} interventionStatus={intervention.status} />
        </StepperPanel>
      </Stepper>

      <div className="flex justify-between items-center w-full bg-gray-50 bottom-0 py-2 sticky z-30">
        {/** <LoadingButton label="Enregistrer" bg_color='bg-primary' loading={loading} on_click={save}/>*/}
        <Dictaphone
          onChange={(value) => {
            focusedFieldName && updateFormField(focusedFieldName, value)
          }}
          fieldName={focusedFieldName}
        />
        {has_role(['arm']) && 
          <Button
            className="bg-transparent border border-primary text-primary px-4 py-2"
            label="Valider et transmettre au MR"
            disabled={intervention.status === InterventionStatus.NOT_STARTED.value}
            onClick={() => setShowAskMedRegHelpModal(true)}
          />
        }
        <InterventionAskMedRegHelpModal
          interventionFromId={data.id}
          interventionId={interventionId}
          visible={showAskMedRegHelpModal}
          setVisible={setShowAskMedRegHelpModal}
          onSend={() => {
            toast.current.show({
              severity: 'success',
              summary: 'Demande envoyée',
              detail: `Demande de passage de main envoyée au Médecin régulateur.`
            });
          }}
        />
      </div>
    </div>
  );
};

export default InterventionFormEditor;
