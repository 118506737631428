import React, { useEffect, useRef, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Card } from 'primereact/card';
import FormModal from '../../../components/modals/FormModal';
import Heading from '../../../components/Utils/Heading';
import { Toast } from 'primereact/toast';
import DateFilter from '../../../components/forms/DateFilter';
import { Icon } from '@iconify/react';

const Trend = () => {
    const [trendData, setTrendData] = useState([]);
    const [responseTimeData, setResponseTimeData] = useState([]);

    const [filter_start_date, setFilterStartDate] = useState(null);
    const [filter_end_date, setFilterEndDate] = useState(null);

    const [filter_visible, setFilterVisible] = useState(false);
    const [btn_loading, setBtnLoading] = useState(false);


    const toast = useRef(null)
    useEffect(() => {
      // Simuler l'appel API avec des données statiques
      setTimeout(() => {
        setTrendData([
          { name: 'Jan', interventions: 400 },
          { name: 'Fev', interventions: 300 },
          { name: 'Mar', interventions: 200 },
          { name: 'Avr', interventions: 278 },
          { name: 'Mai', interventions: 189 },
          { name: 'Jui', interventions: 239 },
          { name: 'Jul', interventions: 349 },
          { name: 'Aou', interventions: 200 },
          { name: 'Sep', interventions: 278 },
          { name: 'Oct', interventions: 189 },
          { name: 'Nov', interventions: 239 },
          { name: 'Dec', interventions: 349 }
        ]);

        setResponseTimeData([
          { month: 'Jan', responseTime: 8 },
          { month: 'Feb', responseTime: 7 },
          { month: 'Mar', responseTime: 6 },
          { month: 'Apr', responseTime: 5 },
          { month: 'May', responseTime: 6 },
          { month: 'Jun', responseTime: 7 },
          { month: 'Jul', responseTime: 8 },
          { month: 'Aug', responseTime: 6 },
          { month: 'Sep', responseTime: 5 },
          { month: 'Oct', responseTime: 6 },
          { month: 'Nov', responseTime: 7 },
          { month: 'Dec', responseTime: 8 }
        ]);
      }, 1000);
    }, []);

    const get_period_stats = async (event) => {
      event.preventDefault();
      setBtnLoading(true);
      try {
          alert("En cours d'implémentation");
          const param = {
            start_date: filter_start_date,
            end_date: filter_end_date
        }
        console.log(param)
          setFilterVisible(false);
          setBtnLoading(false);
          toast.current.show({severity: 'success', summary: 'Succès', detail: 'Statistiques récupérées avec succès.', life: 5000});
      } catch (e) {
          toast.current.show({severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000});
      }
  }
  
  const show_filter_modal = () => {
      setFilterVisible(true);
  };


  return (
    <>
      <Toast ref={toast} />
        <Heading title="Evolution et tendances" />
        <FormModal header={"Filtrer"} visible={filter_visible} setVisible={setFilterVisible} children={<DateFilter on_submit={get_period_stats} loading={btn_loading} set_start_date={setFilterStartDate} set_end_date={setFilterEndDate} type="évolutions et tendances"/>}/>
        
        <div className="w-full flex justify-end items-center px-4 md:px-8">
            <button onClick={show_filter_modal} className="flex gap-2 justify-center items-center px-4 py-1.5 bg-black hover:bg-primary rounded text-white"><Icon icon={'fa:filter'} className="text-xl" /> <span>Filtrer</span></button>
        </div>
      <div className="flex justify-center px-4 my-8 items-center w-full flex-wrap gap-16">
          <div className="w-full lg:w-11/12">
              <Card title="Évolution des Interventions au Fil du Temps">
              <LineChart width={1200} height={300} data={trendData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="interventions" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
              </Card>
          </div>
          <div className="w-full lg:w-11/12">
              <Card title="Temps de Réponse Moyen par Mois">
                  <LineChart width={1200} height={300} data={responseTimeData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="responseTime" stroke="#8884d8" />
                  </LineChart>
              </Card>
          </div>
      </div>
    </>
  );
}

export default Trend