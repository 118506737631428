import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MobileStaffNavbar from '../../components/dashboard/mobile/StaffNavbar';
import '../../assets/css/dashboard.css'
import StaffNavbar from '../../components/dashboard/StaffNavbar';

const sidebar_handler = (check, sideBar, menuButton, crossButton) => {
    if (check) {
        sideBar.style.transform = "translateX(0px)";
        menuButton.classList.add("hidden");
        crossButton.classList.remove("hidden");
    } else {
        sideBar.style.transform = "translateX(-100%)";
        menuButton.classList.remove("hidden");
        crossButton.classList.add("hidden");
    }
};
const menu_handler = (el, val) => {
    let main_list = el.parentElement.parentElement.getElementsByTagName("ul")[0];
    let close_icon = el.parentElement.parentElement.getElementsByClassName("close-m-menu")[0];
    let show_icon = el.parentElement.parentElement.getElementsByClassName("show-m-menu")[0];
    if (val) {
        main_list.classList.remove("hidden");
        el.classList.add("hidden");
        close_icon.classList.remove("hidden");
    } else {
        show_icon.classList.remove("hidden");
        main_list.classList.add("hidden");
        el.classList.add("hidden");
    }
};

const StaffBackOffice = () => {

    useEffect(() => {
        const openMenuButton = document.getElementById('open-m-menu');
        const closeMenuButton = document.getElementById('close-m-menu');
        const menuButton = document.getElementById('menu');
        const crossButton = document.getElementById('cross');
        const sideBar = document.getElementById('mobile-nav');

        const openMenuHandler = () => menu_handler(openMenuButton, true);
        const closeMenuHandler = () => menu_handler(closeMenuButton, false);
        const openSidebarHandler = () => sidebar_handler(true, sideBar, menuButton, crossButton);
        const closeSidebarHandler = () => sidebar_handler(false, sideBar, menuButton, crossButton);

        openMenuButton?.addEventListener('click', openMenuHandler);
        closeMenuButton?.addEventListener('click', closeMenuHandler);
        menuButton?.addEventListener('click', openSidebarHandler);
        crossButton?.addEventListener('click', closeSidebarHandler);

        return () => {
            openMenuButton?.removeEventListener('click', openMenuHandler);
            closeMenuButton?.removeEventListener('click', closeMenuHandler);
            menuButton?.removeEventListener('click', openSidebarHandler);
            crossButton?.removeEventListener('click', closeSidebarHandler);
        };
    }, []);

    return (
        <div className='w-full h-full'>

            <StaffNavbar />

            <MobileStaffNavbar />

            <main className="bg-gray-50 p-4 lg:p-6 xl:p-8 min-h-screen mt-16 md:mt-0">
                <Outlet />
            </main>

            <footer className="text-sm text-right p-2">
                &copy; { new Date().getFullYear() } Digitalisation des interventons | SAMU
            </footer>
        </div>
        
    )
}

export default StaffBackOffice
