import React, { useEffect, useRef, useState } from 'react'
import { create_product, decrease_stock, delete_product, get_products, increase_stock, update_product } from '../../services/management/product_stock.service';
import { INTERNAL_ROUTES } from '../../utils/internal_routes';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import FormModal from '../../components/modals/FormModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Toast } from 'primereact/toast';
import Heading from '../../components/Utils/Heading';
import Loading from '../../components/Utils/Loading';
import CardStatOne from '../../components/cards/stats/CardStatOne';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NewProduct from '../../components/forms/products/NewProduct';
import UpdateProduct from '../../components/forms/products/UpdateProduct';
import UpdateProductQuantity from '../../components/forms/products/UpdateProductQuantity';
import { get_user_interventions } from '../../services/interventions/intervention.service';
import { get_medical_members, getMembersOptions } from '../../services/management/users.service';

const StockMaterial = () => {
  const [products, setProducts] = useState([]);
  const [staffMembers, setStaffMembers] = useState([])
  const [intervention, setInterventions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btn_loading, setBtnLoading] = useState(false);

  const [error, setError] = useState('');

  const [selected_id, setSelectedId] = useState(null);
  const [selected_product, setSelectedProduct] = useState({});

  const [add_visible, setAddVisible] = useState(false);
  const [update_quantity_visible, setUpdateQuantityVisible] = useState(false);
  const [update_visible, setUpdateVisible] = useState(false);
  const [delete_visible, setDeleteVisible] = useState(false);

  const toast = useRef(null);

  useEffect(() => {
    const fetch_data = async () => {
      try {
        // FIXME: Pagination
        const data = await get_products(1000, 0);
        const intervention_data = await get_user_interventions();
        setInterventions(intervention_data.results);
        setProducts(data.results || data);
        setLoading(false);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    };
    const setStaffMembersData = async () => {
      setLoading(true);
      try {
        const response = await get_medical_members(100, 0, {
          // available: true,
          // "groups__name": Groups.MEDICAL_REGULATOR.value
        });
        const availableMembers = getMembersOptions(response.results)
        setStaffMembers(availableMembers)
        setLoading(false);
      } catch (e) {
        console.log('----err ', e)
        setLoading(false);
      }
    };
    setStaffMembersData();

    fetch_data();
  }, []);

  const handle_add_product = async (event) => {
    event.preventDefault();
    setBtnLoading(true);
    const form_data = new FormData(event.target);
    const product = Object.fromEntries(form_data.entries());
    console.log(product);
    try {
      const data = await create_product(product);
      setProducts([...products, data.results || data]);
      setAddVisible(false);
      setBtnLoading(false);
      handle_notification('success', 'Succès de l\'ajout', 'Produit ajouté au stock avec succès');
    } catch (e) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de l\'ajout', e.message || e);
    }
  }

  const handle_update_product = async (event) => {
    event.preventDefault();
    const form_data = new FormData(event.target);
    const updated_data = Object.fromEntries(form_data.entries());
    setBtnLoading(true);
    if (!selected_id) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de modification', 'Veuillez selectionner un produit');
      return;
    }
    try {
      const data = await update_product(updated_data, selected_id);
      const updated_product = data.results || data;
      const updated_products = products.map(amb => amb.id === selected_id ? { ...amb, ...updated_product } : amb);

      setProducts(updated_products);
      setUpdateVisible(false);
      setBtnLoading(false);
      handle_notification('success', 'Succès de la mise à jour', 'Produit modifié avec succès');
    } catch (e) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de la mise à jour', e.message || e);
    }
  }

  const handle_update_product_quantity = async (event) => {
    event.preventDefault();
    const form_data = new FormData(event.target);
    let updated_data = {}

    updated_data['quantity'] = form_data.get('quantity')
    setBtnLoading(true);
    if (!selected_id) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de modification', 'Veuillez selectionner un produit');
      return;
    }
    try {
      let data = {}
      if (form_data.get('action') === 'retirer') {
        updated_data['intervention'] = form_data.get('intervention')
        console.log(updated_data);
        data = await decrease_stock(updated_data, selected_id);
      } else data = await increase_stock(updated_data, selected_id);

      const updated_product = data.data;
      const updated_products = products.map(amb => {
        if (amb.id === selected_id) {
          console.log("-*----pro ", typeof amb.quantity, typeof updated_data.quantity)
          amb.quantity += (form_data.get('action') === 'retirer') ? -parseFloat(updated_data.quantity) : parseFloat(updated_data.quantity)
        }
        return amb
      });

      setProducts(updated_products);
      setUpdateQuantityVisible(false);
      setBtnLoading(false);
      handle_notification('success', 'Succès de la mise à jour', data.message);
    } catch (e) {
      console.log(e.message);
      setBtnLoading(false);
      handle_notification('error', 'Erreur de la mise à jour', e.message || e);
    }
  }

  const handle_delete_product = async () => {
    setBtnLoading(true);
    if (!selected_id) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de suppression', 'Veuillez selectionner un produit');
      return;
    }
    try {
      await delete_product(selected_id);
      const remaining_products = products.filter(amb => amb.id !== selected_id);
      setProducts(remaining_products);
      setDeleteVisible(false);
      setBtnLoading(false);
      handle_notification('success', 'Succès de la suppression', 'Produit supprimée avec succès');
    } catch (e) {
      setBtnLoading(false);
      handle_notification('error', 'Erreur de la suppression', e.message);
    }
  }


  // Messsage management
  const handle_notification = (severity, summary, detail) => {
    toast.current.show({ severity: severity, summary: summary, detail: detail, life: 5000 });
  }

  // Modal handler : 
  const add_product_preview = () => {
    setAddVisible(true);
  }

  const update_product_preview = (product) => {
    setUpdateVisible(true);
    setSelectedId(product.id);
    setSelectedProduct(product);
  }

  const update_product_quantity = (product) => {
    setUpdateQuantityVisible(true)
    setSelectedId(product.id);
    setSelectedProduct(product);
  }

  const confirm_deletion = (product_id) => {
    setDeleteVisible(true);
    setSelectedId(product_id);
  };
  const cancel_deletion = () => {
    setDeleteVisible(false);
  }

  const product_types = {
    'medical-equipment': 'Équipement matériel',
    'medication': 'Médicament'
  }

  // Modal headers
  const modal_header = (text) => {
    return <p className="lg:text-xl font-semibold p-2">{text}</p>
  }

  // DataTable columns template
  const product_type_template = (product) => {
    return product_types[product.product_type]
  };

  const action_template = (row_data) => {
    return (
      <div className="flex justify-center gap-2 items-center">
        <NavLink to={row_data.id + ''} className="flex justify-start gap-2"> <Icon icon={'ph:list-magnifying-glass'} className="text-gray-600 xl:text-lg" /></NavLink>

        <Icon onClick={() => update_product_quantity(row_data)} className="xl:text-lg text-sky-500 cursor-pointer hover:-translate-y-1" icon={'dashicons:update'} />
        <Icon onClick={() => update_product_preview(row_data)} className="xl:text-lg text-sky-500 cursor-pointer hover:-translate-y-1" icon={'eva:edit-2-fill'} />

        <Icon onClick={() => confirm_deletion(row_data.id)} className="xl:text-lg text-red-500 cursor-pointer hover:-translate-y-1" icon={'eva:trash-2-outline'} />
      </div>
    );
  }


  return (
    <React.Fragment>
      <FormModal header={modal_header('Ajouter un produit')} children={<NewProduct on_submit={handle_add_product} loading={btn_loading} />} visible={add_visible} setVisible={setAddVisible} />
      <FormModal header={modal_header('Mettre à jour les informations d\'un produit')} children={<UpdateProduct on_submit={handle_update_product} loading={btn_loading} data={selected_product} />} visible={update_visible} setVisible={setUpdateVisible} />
      <FormModal
        header={modal_header('Mettre à jour la quantité d\'un produit')}
        children={
          staffMembers?.length && (
            <UpdateProductQuantity
            staffMembers={staffMembers}
            on_submit={handle_update_product_quantity}
            loading={btn_loading}
            data={intervention}
          />
          )
        }
        visible={update_quantity_visible}
        setVisible={setUpdateQuantityVisible}
      />
      <ConfirmModal visible={delete_visible} setVisible={setDeleteVisible} title="Supprimer un produit" content="Voulez-vous vraiment supprimer ce produit ?" icon="healthicons:warning" accept={() => { handle_delete_product() }} reject={() => { cancel_deletion() }} />


      <Toast ref={toast} />


      {/* Page content */}
      <div className="p-4 lg:p-8">
        <Heading title="Inventaire du stock" />
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <Loading />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
            ) :
              (
                <React.Fragment>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center my-8">
                      <CardStatOne icon="mdi:ambulance" title="Produit(s)" value={products.length} bg_color="bg-primary" />
                    </div>
                    <div className="flex justify-end items-center my-4">
                      <button onClick={() => add_product_preview()} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-secondary text-white shadow">
                        <Icon icon="mi:add" className="text-xl" />
                        <span>Ajouter</span>
                      </button>
                    </div>
                  </div>



                  {products.length === 0 ? (
                    <p className="text-center my-8 text-xl font-semibold"> Aucun produit disponible dans le stock !</p>
                  ) :
                    (
                      <div className="card my-4">
                        <DataTable value={products} showGridlines={true} sortMode="multiple" removableSort paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                          <Column field="name" header="Nom" sortable style={{ width: '25%' }}></Column>
                          <Column field="product_type" header="Type" body={product_type_template} sortable style={{ width: '20%' }}></Column>
                          <Column field="quantity" header="Quantité disponible" sortable style={{ width: '15%' }}></Column>
                          <Column field="description" header="Description" sortable style={{ width: '35%' }}></Column>
                          <Column header="Action" body={action_template} style={{ width: '5%' }}></Column>
                        </DataTable>
                      </div>
                    )}
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default StockMaterial
