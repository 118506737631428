
import LoadingButton from '../../Utils/LoadingButton';

const InterventionJustification = ({on_submit, loading, type}) => {
    let name = type === "canceled" ? "cancel_justification" : "white_out_justification";
    let label = type === "canceled" ? "Justification de l'annulation de l'intervention" : "Justification de la sortie blanche";
    return (
        <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
            
            <div className="my-4">
                <div className="relative w-full">
                    <label htmlFor="justification" className="text-lg text-gray-600 mb-4">{label}</label>
                    <textarea id="justification" name={name} rows="4" className="block p-2 w-full text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-primary focus:border-primary" required></textarea>                                        
                </div>
            </div>

            <LoadingButton label="Valider" loading= {loading} />
        </form>
    )
}


export default InterventionJustification