import L from 'leaflet';

export const AmbulanceSvgIcon = L.divIcon({
  html: `
  <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 506.4 506.4" xml:space="preserve">
    <path style="fill:#42A5F5;" d="M333.6,97.8c-11.2,0-20.4,11.2-22.8,22.4H356C354,109,344.8,97.8,333.6,97.8z"/>
    <path style="fill:#BF360C;" d="M12,332.6l-6,4v28h37.6c0.4-8,2.8-24,6.4-32H12z"/>
    <path style="fill:#EF5350;" d="M107.2,305c34,0,57.6,28,59.6,59.6h171.6c1.6-36,29.2-61.2,63.6-61.2c22.4,0,42,13.2,53.6,29.2H492
      v-32h0.8c-10.8,0-19.2-8.8-19.2-20c0-10.8,8-20,19.2-20h-0.4v-12l-5.2-8.8c-0.4,0.4-66.8-99.2-66.8-99.2l-10.4-16h-51.2
      c0,0,0,0-0.4,0c0,0,0,0-0.4,0h-47.2c0,0,0,0-0.4,0s0,0-0.4,0H21.6c-3.2,0-7.6,2-7.6,4.8v76c12,1.2,24,8.4,24,27.6v75.6
      c0,2.4,0.4,4-2,4H14v20h40.8C66,317,85.6,305,107.2,305z"/>
    <path style="fill:#BF360C;" d="M462,364.6h42v-28l-14-4h-38C455.6,340.6,461.6,356.6,462,364.6z"/>
    <g>
      <path style="fill:#2E7D32;" d="M354.4,125.4H354C354,125.4,354,125.8,354.4,125.4C354.4,125.8,354.4,125.4,354.4,125.4z"/>
      <path style="fill:#2E7D32;" d="M306.8,125.4h-0.4C306.4,125.4,306.4,125.8,306.8,125.4C306.4,125.8,306.4,125.4,306.8,125.4z"/>
    </g>
    <path style="fill:#E0E0E0;" d="M470.4,213l-44-66.8c0,0-0.4,0.4-0.8,0.4c-0.8,0.4-1.6,0.8-2.4,0.8h-94.8V239h158.8h0.4L470.4,213z"
      />
    <path style="fill:#BCAAA4;" d="M34,233c0-14.8-12-18.4-20-19.6v95.2h20V233z"/>
    <path style="fill:#2E7D32;" d="M492.4,265.4c-8.8,0-15.2,7.2-15.2,15.6c0,8.8,6,15.6,14.8,15.6h-0.4v-31.2
      C491.6,265.4,492.4,265.4,492.4,265.4z"/>
    <path style="fill:#616161;" d="M442.4,363c-0.8-22.4-19.2-40.8-42-40.8s-41.2,18-42,40.8c0,0.4,0,0.8,0,1.6l0,0
      c0,23.2,18.8,42,42,42s42-18.8,42-42l0,0C442.8,364.2,442.8,363.8,442.4,363z"/>
    <path style="fill:#BCAAA4;" d="M421.6,365.4c0-1.2-0.4-2.4-0.8-3.6c-0.4-1.2-0.4-2-0.8-3.2c-0.8-5.2-9.6-10.4-12.8-12
      c-2-0.8-4.4-1.2-6.8-1.2c-11.2,0-20.4,8.8-20.8,19.6c0,0.4,0,0.8,0,1.6l0,0c0,11.6,9.6,21.2,21.2,21.2c11.6,0,21.2-9.6,21.2-21.2
      l0,0C421.6,366.6,421.6,365.8,421.6,365.4z"/>
    <path style="fill:#616161;" d="M105.2,321.8c-22.8,0-41.6,18.8-41.6,41.6l0,0c0,22.8,18.8,41.6,41.6,41.6s41.6-18.8,41.6-41.6l0,0
      C146.8,340.2,128.4,321.8,105.2,321.8z"/>
    <path style="fill:#BCAAA4;" d="M123.2,357.8l-5.2-7.2l-8-6c-2-0.8-4.4-1.2-6.4-1.2c-11.6,0-20.8,9.2-20.8,20.8l0,0
      c0,11.6,9.2,20.8,20.8,20.8s20.8-9.2,20.8-20.8l0,0C124,362.2,123.6,360.2,123.2,357.8z"/>
    <path style="fill:#EEEEEE;" d="M130.8,241h36.4c2,0,2,1.6,2,4v35.6c0,2,3.2,4,5.6,4h28.4c2.4,0,2-2,2-4V245c0-2.4,3.6-4,6-4h35.2
      c2.4,0,2.8-2,2.8-4v-28c0-2-0.4-4-2.8-4h-35.2c-2.4,0-6-2-6-4v-36c0-2,0-4-2.4-4h-28c-2.4,0-5.6,2-5.6,4v36c0,2,0.4,4-2,4h-36.4
      c-2.4,0-5.6,2-5.6,4v28C125.6,239,128.4,241,130.8,241z"/>
    <polygon style="fill:#BF360C;" points="165.2,364.6 340.4,364.6 348.4,332.6 156.4,332.6 "/>
    <path d="M502,370.6h-42c-2.4,0-4-1.6-4-4s1.6-4,4-4h38v-24h-4c-2.4,0-4-1.6-4-4v-85.2l-82.4-121.6H22c-4.8,0-6,4.8-6,6V335
      c0,2.4-1.6,4-4,4H8v24h38c2.4,0,4,1.6,4,4s-1.6,4-4,4H4c-2.4,0-4-1.6-4-4v-32c0-2.4,1.6-4,4-4h4V133.4c0-5.6,4.4-14,14-14h388
      c1.2,0,2.4,0.8,3.2,1.6l84.4,124.4c0.4,0.8,0.8,1.6,0.8,2.4v82.4h4c2.4,0,4,1.6,4,4v32C506,369,504.4,370.6,502,370.6z"/>
    <path d="M340.8,370.6H165.6c-2.4,0-4-1.6-4-4s1.6-4,4-4h175.2c2.4,0,4,1.6,4,4S342.8,370.6,340.8,370.6z"/>
    <path d="M356,125.8c-2.4,0-4-1.6-4-4c0-10.8-8.8-20-20-20s-20,8.8-20,20c0,2.4-1.6,4-4,4s-4-1.6-4-4c0-15.2,12.4-28,28-28
      s28,12.4,28,28C360,123.8,358.4,125.8,356,125.8z"/>
    <path d="M484,243H326.8c-2.4,0-4-1.6-4-4v-91.6c0-2.4,1.6-4,4-4h61.6c2.4,0,4,1.6,4,4s-1.6,4-4,4h-57.6V235H484c2.4,0,4,1.6,4,4
      S486.4,243,484,243z"/>
    <path d="M36,315H12c-2.4,0-4-1.6-4-4V209.4c0-2,1.6-4,4-4c0.4,0,12.4,0,20.4,8c4.8,4.8,7.6,11.6,7.6,20V311C40,313,38,315,36,315z
      M16,307h16v-73.6c0-6-1.6-10.8-5.2-14.4c-3.2-3.2-7.6-4.8-10.8-5.2V307z"/>
    <path d="M105.2,412.6c-26.4,0-48-21.6-48-48s21.6-48,48-48s48,21.6,48,48S131.6,412.6,105.2,412.6z M105.2,324.6c-22,0-40,18-40,40
      s18,40,40,40s40-18,40-40S127.2,324.6,105.2,324.6z"/>
    <path d="M105.2,392.6c-15.2,0-28-12.4-28-28s12.4-28,28-28c3.2,0,6.4,0.4,9.6,1.6c2,0.8,3.2,3.2,2.4,5.2s-3.2,3.2-5.2,2.4
      c-2-0.8-4.4-1.2-6.8-1.2c-10.8,0-20,8.8-20,20s8.8,20,20,20s20-8.8,20-20c0-2.4-0.4-4.8-1.2-6.8s0.4-4.4,2.4-5.2
      c2-0.8,4.4,0.4,5.2,2.4c1.2,3.2,1.6,6.4,1.6,9.6C133.2,379.8,120.8,392.6,105.2,392.6z"/>
    <path d="M400,412.6c-26.4,0-48-21.6-48-48s21.6-48,48-48s48,21.6,48,48S426.4,412.6,400,412.6z M400,324.6c-22,0-40,18-40,40
      s18,40,40,40s40-18,40-40S422,324.6,400,324.6z"/>
    <path d="M400,392.6c-15.2,0-28-12.4-28-28s12.4-28,28-28c3.2,0,6.4,0.4,9.6,1.6c2,0.8,3.2,3.2,2.4,5.2s-3.2,3.2-5.2,2.4
      c-2-0.8-4.4-1.2-6.8-1.2c-10.8,0-20,8.8-20,20s8.8,20,20,20c10.8,0,20-8.8,20-20c0-2.4-0.4-4.8-1.2-6.8s0.4-4.4,2.4-5.2
      c2-0.8,4.4,0.4,5.2,2.4c1.2,3.2,1.6,6.4,1.6,9.6C428,379.8,415.6,392.6,400,392.6z"/>
    <path d="M165.2,368.6c-2.4,0-4-1.6-4-4c0-30.8-25.2-55.6-55.6-55.6C74.8,309,50,334.2,50,364.6c0,2.4-1.6,4-4,4s-4-1.6-4-4
      c0-35.2,28.8-63.6,63.6-63.6c35.2,0,63.6,28.8,63.6,63.6C169.2,367,167.2,368.6,165.2,368.6z"/>
    <path d="M460,367.4c-2.4,0-4-1.6-4-4c0-30.8-25.2-55.6-55.6-55.6c-30.8,0-55.6,25.2-55.6,55.6c0,2.4-1.6,4-4,4s-4-1.6-4-4
      c0-35.2,28.8-63.6,63.6-63.6c35.2,0,63.6,28.8,63.6,63.6C464,365.4,462,367.4,460,367.4z"/>
    <path d="M38,338.6H6c-2.4,0-4-1.6-4-4s1.6-4,4-4h32c2.4,0,4,1.6,4,4S40,338.6,38,338.6z"/>
    <path d="M332.8,338.6H177.6c-2.4,0-4-1.6-4-4s1.6-4,4-4h155.2c2.4,0,4,1.6,4,4S334.8,338.6,332.8,338.6z"/>
    <path d="M492,338.6h-24c-2.4,0-4-1.6-4-4s1.6-4,4-4h24c2.4,0,4,1.6,4,4S494.4,338.6,492,338.6z"/>
    <path d="M491.6,301c-10.8,0-20-8.8-20-20s8.8-20,20-20c2.4,0,4,1.6,4,4s-1.6,4-4,4c-6.4,0-12,5.2-12,12s5.2,12,12,12
      c2.4,0,4,1.6,4,4S494,301,491.6,301z"/>
    <path d="M201.2,287h-27.6c-6.8,0-10.4-3.6-10.4-10v-29.6h-32c-6.8,0-11.6-4.8-11.6-11.2v-26.8c0-2.8,1.2-10,10.8-10h32.8v-34
      c0-2.4,0.8-10,11.6-10h26c8.4,0,10.4,5.6,10.4,10v34h33.2c9.6,0,10.8,6.8,10.8,10v26c0,6.8-4.4,11.6-10.8,11.6h-33.2v29.6
      C211.2,283.4,207.6,287,201.2,287z M171.6,278.6c0,0,0.4,0.4,2,0.4h27.6c1.6,0,2-0.4,2-0.4s0.4-0.4,0.4-2V243c0-2.4,1.6-4,4-4h37.2
      c0.8,0,2.8,0,2.8-3.6v-26c0-1.6,0-2-2.8-2h-37.2c-2.4,0-4-1.6-4-4v-38c0-1.2-0.4-1.6-0.4-1.6l0,0c0,0-0.4-0.4-2-0.4h-26
      c-3.6,0-3.6,1.2-3.6,2v38c0,2.4-1.6,4-4,4h-36.8c-2.8,0-2.8,0.4-2.8,2v26.4c0,3.2,2.8,3.2,3.6,3.2h36c2.4,0,4,1.6,4,4v33.6
      C171.2,278.2,171.6,278.6,171.6,278.6L171.6,278.6z"/>
  </svg>`,
  className: "svg-icon",
  iconSize: [35, 40],
  iconAnchor: [12, 40]
});
