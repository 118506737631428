import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { get_current_user } from "../services/auth.service";
import Loading from './../components/Utils/Loading';
import { INTERNAL_ROUTES } from "../utils/internal_routes";

const PrivateRoute = ({ roles }) => {
    const { user, updateUser } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user) {
            get_current_user()
                .then(currentUser => {
                    if (currentUser) {
                        updateUser(currentUser);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [user, updateUser]);

    if (loading) {
        return <Loading />;
    }

    if (!user) {
        return <Navigate to={INTERNAL_ROUTES.LOGIN} />;
    }
    if (roles && user.groups && user.groups.length > 0) {
        const user_roles = user.groups.map((group) => group.name);
        const is_allowed = roles.some((role) => user_roles.includes(role));
        if (!is_allowed) {
            return <Navigate to={INTERNAL_ROUTES.FORBIDDEN} />;
        }
    }

    return <Outlet />;
};

export default PrivateRoute;
