import axios from './customAxios';
import { STATS_URL } from "./urls";

export const get_kpi_data = async () => {
    try {
        const response = await axios.get(STATS_URL);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};

