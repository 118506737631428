import 'primeicons/primeicons.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../utils/internal_routes';
import { Icon } from '@iconify/react';
import useNotificationSocket from '../../pages/common/hooks/useNotificationSocket';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { NOTIF_REQUEST_TYPE_DISPLAY, NotificationLevel, NotificationRequestType, NotificationStatus, NotificationType } from '../../pages/common/constants';


const NOTIF_CATEGORY = {
  SIMPLE: "SIMPLE",
  MED_HELP_REQUEST: "MED_HELP_REQUEST",
  URGENT_INFO: "URGENT_INFO",
};
const NOTIF_CATEGORY_MAP_LABEL = {
  [NOTIF_CATEGORY.MED_HELP_REQUEST]: "Accepter",
}
const NotificationWithDialogCategories = [
  NOTIF_CATEGORY.MED_HELP_REQUEST,
  NOTIF_CATEGORY.URGENT_INFO
]

const UserMenuNotification = ({ roles }) => {
  const is_admin = roles.some(group => group.name === 'Admin');
  const notification_route = is_admin ? INTERNAL_ROUTES.ADMIN_NOTIFICATION : INTERNAL_ROUTES.NOTIFICATION;
  const unread_notifications = 0;
  const [showNotificationDialog, setShowNotificationDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationCategory, setNotificationCategory] = useState(NOTIF_CATEGORY.SIMPLE)
  const {user} = useContext(UserContext);
  const [notification, notificationSocket] = useNotificationSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (!notification || String(notification?.receiver) !== String(user?.id)) return

    let _notificationCategory = NOTIF_CATEGORY.SIMPLE
    if (notification.request_type === NotificationRequestType.MED_REG_HELP_IN_INTERVENTION.value) {
      _notificationCategory = NOTIF_CATEGORY.MED_HELP_REQUEST
    }
    console.log('---------notification ', notification, [NotificationLevel.HIGH.value, NotificationLevel.CRITICAL.value].includes(notification.level))
    if ([NotificationLevel.HIGH.value, NotificationLevel.CRITICAL.value].includes(notification.level)) {
      _notificationCategory = NOTIF_CATEGORY.MED_HELP_REQUEST
      setShowNotificationDialog(true)
    }
    console.log('---------notification ', notification, _notificationCategory)
    setNotificationCategory(_notificationCategory)

  }, [notification])

  const onSubmitNotificationRequest = () => {
    setShowNotificationDialog(false)
    switch (notificationCategory) {
      case NOTIF_CATEGORY.MED_HELP_REQUEST:
        handleNotificationMedHelpRequest()
      case NOTIF_CATEGORY.MED_HELP_REQUEST:
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_SUMMARY}/${notification.target_id}`)
      default:
        break
    }
  }

  const handleNotificationMedHelpRequest = () => {
    notificationSocket.send(
      JSON.stringify({
        id: notification.id,
        target_id: notification.target_id,
        request_type: NotificationRequestType.MED_REG_HELP_IN_INTERVENTION.value,
        notif_type: NotificationType.RESPONSE.value,
      })
    );
    navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_SUMMARY}/${notification.target_id}`)
  }

  return (
    <>
      <button type="button" className="relative inline-flex items-center px-3 py-2.5 text-sm font-medium text-center bg-secondary rounded mr-2">
        <Icon icon="ic:round-notifications" className="text-white xl:text-lg" />
        <span className="sr-only">Notifications</span>
        {unread_notifications > 0 && (
          <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-lightGreen border border-white rounded-full -top-1 -end-1 dark:border-gray-900">
            {unread_notifications}
          </div>
        )}
      </button>

      {notification && (
        <Dialog
          header={NOTIF_REQUEST_TYPE_DISPLAY[notification.request_type] || "Notification Urgente"}
          visible={showNotificationDialog}
          style={{ width: '50vw' }}
          onHide={() => setShowNotificationDialog(false)}
        >
          <p>{notification.description}</p>
          <Button
            className="bg-primary text-white px-4 py-2 mt-5"
            label={NOTIF_CATEGORY_MAP_LABEL[notificationCategory] || "Voir"}
            onClick={onSubmitNotificationRequest}
            loading={loading}
            disabled={loading}
          />
        </Dialog>
      )}
    </>
  );
};

export default UserMenuNotification;
