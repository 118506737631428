import { Icon } from '@iconify/react';

const CardStatThree = ({title, value, icon, color, bg_color, card_color="bg-white", text_color="text-gray-500"}) => {
    return (
        <div className={`${card_color} flex items-center p-4 rounded shadow`}>
            <div className={`flex flex-shrink-0 items-center justify-center h-14 w-14 rounded ${bg_color}`}>
                <Icon icon={icon} className={`text-2xl ${color}`} />
            </div>
            <div className="flex-grow flex flex-col ml-4">
                <span className={` ${text_color} text-xl font-bold` }>{value}</span>
                <div className="flex items-center justify-between">
                    <span className={`${text_color}`}>{title}</span>
                </div>
            </div>
        </div>
    );
};

export default CardStatThree;
