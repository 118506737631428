import { Avatar, Dropdown } from "flowbite-react"
import { logout } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";

const get_initials = (first_name, last_name) => {
    const first_initial = first_name.charAt(0).toUpperCase();
    const last_initial = last_name.charAt(0).toUpperCase();
    return `${first_initial}${last_initial}`;
};

const UserMenuDropdown = ({email, first_name, last_name}) => {
    const navigate = useNavigate()
    const logout_user = () =>{
        if (logout) {
            navigate(INTERNAL_ROUTES.LOGIN)
        }
    }
    return (
        <>
        <Dropdown
            label={
                <div className="flex flex-wrap items-center gap-2">
                    <Avatar placeholderInitials={get_initials(first_name, last_name)} color="gray" />
                </div>
            }
            arrowIcon={true}
            inline
            >
            <Dropdown.Header>
                <span className="block text-sm">{first_name} {last_name}</span>
                <span className="block truncate text-sm font-medium">{email}</span>
            </Dropdown.Header>
            <Dropdown.Item>Notifications</Dropdown.Item>
            <Dropdown.Item>Mon Profil</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logout_user}> Déconnexion</Dropdown.Item>
        </Dropdown>        
        </>
    )
}

export default UserMenuDropdown