const Error403 = () => {
    const go_back = () => {
        window.history.back();
    }
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">403</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Accès refusé.</p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Désolé, vous n'êtes pas autorisé.e à accéder à cette page. Veuillez contacter l'administrateur si vous pensez qu'il s'agit d'une erreur.</p>
                    <button onClick={go_back} className="inline-flex text-white bg-secondary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded text-sm px-4 py-2 text-center my-4">Retour</button>
                </div>   
            </div>
        </section>

    )
}

export default Error403;