import { InputNumber } from 'primereact/inputnumber';

const CustomInputNumber = ({ ...props }) => {
    return (
        <InputNumber
            {...props}
            className="focus:ring-primary focus:border-primary"
        />
    );
}

export default CustomInputNumber