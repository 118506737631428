import { INTERVENTION_FORM_URL } from "../urls";
import axios from '../customAxios';


export const get_intervention_forms = async (params) => {
    try {
        const response = await axios.get(INTERVENTION_FORM_URL, {params});
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const create_intervention_form = async (data) => {
    try {
        const response = await axios.post(INTERVENTION_FORM_URL, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_intervention_form = async (id, data) => {
    try {
        const response = await axios.put(`${INTERVENTION_FORM_URL}${id}/`, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
  }
};

export const delete_intervention_form = async (id) => {
    try {
        const response = await axios.delete(`${INTERVENTION_FORM_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
