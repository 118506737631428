

const Heading = ({ title }) => {
    return (
        <h2 className="flex flex-row flex-nowrap items-center">
            <span className="flex-grow block border-t border-secondary"></span>
            <span className="flex-none block mx-4 px-4 py-2 text-lg rounded leading-none font-medium bg-secondary text-white">
                {title}
            </span>
            <span className="flex-grow block border-t border-secondary"></span>
        </h2>
    )
}

export default Heading