import { Spinner } from "flowbite-react";

const Loading = () => {

    return (
        <div className="text-center">
            <Spinner aria-label="Center-aligned spinner" />
        </div>
    )
}

export default Loading;
