import React, { useContext, useEffect, useRef, useState } from 'react';
import { createAuthWebSocket } from '../../../services/auth.service';

const SAMU_COMPANY_ID = 1


const useNotificationSocket = () => {
  const [notificationSocket, setNotificationSocket] = useState(null);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const newSocket = createAuthWebSocket({
      resource: "notifications",
      roomName: SAMU_COMPANY_ID
    })

    newSocket.onopen = () => {
      console.log('Notifications WebSocket connected');
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setNotification(data)
    };

    newSocket.onclose = () => {
      console.log('Notifications WebSocket disconnected');
    };

    setNotificationSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  return [notification, notificationSocket]
};

export default useNotificationSocket;
