import { Checkbox, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import { Icon } from "@iconify/react";
import LoadingButton from "../Utils/LoadingButton";

const LoginForm = ({handle_submit, handle_change, password_field_ref, loading}) => {
    const [ hidePwd, setHidePwd ] = useState(true);

    return (
        <form onSubmit={handle_submit} className="flex max-w-md mx-auto flex-col gap-4">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="login-username" value="Nom d'utilisateur" />
                </div>
                <TextInput name="username" id="login-username" type="text" 
                    onChange={handle_change} 
                    placeholder="Entrez votre nom d'utilisateur"
                    required 
                />
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="login-pwd" value="Mot de passe" />
                </div>
                <div className="relative">
                    <TextInput ref={password_field_ref} name="password" id="login-pwd" {...(hidePwd ? {type: 'password'} : {type: 'text'})} 
                        onChange={handle_change} 
                        required 
                    />
                    <div className="absolute z-20 hover:bg-gray-100 cursor-pointer right-2 top-1/2 -translate-y-1/2 p-1 rounded">
                        {hidePwd ? <Icon icon="mdi:eye" onClick={() => setHidePwd(!hidePwd)} /> : <Icon icon="mdi:eye-off" onClick={() => setHidePwd(!hidePwd)} />}
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-2">
                <Checkbox name="remember_me" id="remember-me" onChange={handle_change} className="w-5 h-5 text-primary focus:text-primary"/>
                <Label htmlFor="remember-me">Se souvenir de moi</Label>
            </div>

            <div className="flex justify-center my-4">
                <LoadingButton label="Se connecter" loading={loading} bg_color="bg-primary" />
            </div>
        </form>
    );
}

export default LoginForm