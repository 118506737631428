
export const NotificationType = {
    INFO: { value: "info", label: "Information" },
    REQUEST: { value: "request", label: "Request" },
    RESPONSE: { value: "response", label: "Response" }
};

export const NotificationStatus = {
    PENDING: { value: "pending", label: "Pending" },
    SEEN: { value: "seen", label: "Seen" },
    READ: { value: "read", label: "Read" },
    REQUEST_ACCEPTED: { value: "request-accepted", label: "Request accepted" },
    REQUEST_REJECTED: { value: "request-rejected", label: "Request rejected" }
};

export const NotificationLevel = {
    LOW: { value: "low", label: "Faible" },
    MEDIUM: { value: "medium", label: "Moyen" },
    HIGH: { value: "high", label: "Élevé" },
    CRITICAL: { value: "critical", label: "Critique" }
};


export const Groups = {
    ADMIN: { value: "admin", label: "Administrateur" },
    COMPANY_DIRECTOR: { value: "company-director", label: "Directeur de l'entreprise" },
    BRANCH_DIRECTOR: { value: "branch-director", label: "Directeur de branche" },
    CHIEF_SUPERVISOR: { value: "chief-supervisor", label: "Superviseur en chef" },
    BRANCH_SUPERVISOR: { value: "branch-supervisor", label: "Superviseur de branche" },
    MEDICAL_REGULATOR: { value: "medical-regulator", label: "Médecin Régulateur" },
    ARM: { value: "arm", label: "ARM" },
    AMBULANCE_CHIEF: { value: "ambulance-chief", label: "Chef d'équipe ambulance" },
    AMBULANCE_MEMBER: { value: "ambulance-member", label: "Ambulancier" },
    AMBULANCE_DRIVER: { value: "ambulance-driver", label: "Chauffeur d'ambulance" },
    PATIENT: { value: "patient", label: "Patient" },
    EMERGENCY_NURSE: { value: "emergency-nurse", label: "Infirmier urgentiste" },
    RESCUER: { value: "rescuer", label: "Secouriste" },
    PERMANENT_STAFF: { value: "permanent-staff", label: "Personnel permanent" }
};

export const GROUPS_DISPLAY = {
    "admin": "Administrateur",
    "company-director": "Directeur Général",
    "branch-director": "Directeur de Branche",
    "chief-supervisor": "Surveillant Général",
    "medical-regulator": "Médecin Régulateur",
    "arm": "ARM",
    "ambulance-chief": "Chef d'équipe ambulance",
    "ambulance-member": "Ambulancier",
    "ambulance-driver": "Chauffeur d'Ambulance",
    "patient": "Patient",
    "emergency-nurse": "Infirmier urgentiste",
    "rescuer": "Secouriste",
    "permanent-staff": "Membre permanent",
}

export const NotificationRequestType = {
    MED_REG_HELP_IN_INTERVENTION: {
        value: "med-reg-help-intervention",
        label: "Demande d'assistance d'un ARM durant une intervention."
    }
};
export const NOTIF_REQUEST_TYPE_DISPLAY = {
    "med-reg-help-intervention": NotificationRequestType.MED_REG_HELP_IN_INTERVENTION.label
}

export const InterventionStatus = {
    NOT_STARTED: { value: "not-started", label: "Not started" },
    TEAM_FORMED: { value: "team-formed", label: "Ambulance team formed" },
    AMBULANCE_RETURNED: { value: "ambulance-returned", label: "Ambulance returned" },
    AMBULANCE_ON_GOING: { value: "ambulance-on-going", label: "Ambulance on going" },
    AMBULANCE_ARRIVED: { value: "ambulance-arrived", label: "Ambulance arrived to patient" },
    CANCELED: { value: "canceled", label: "Canceled" },
    WHITE_OUT: { value: "white-out", label: "White out" },
    DONE: { value: "done", label: "Done" }
};
