import logoSamu from '../../assets/images/logo.png';

const LoginFormHeader = () => {
    return (
        <div className="text-center mb-10">
        <div className="mb-4 w-full flex justify-center items-center gap-4">
            <img className="w-20 sm:w-24 md:w-28" src={logoSamu} alt="Logo" />
        </div>
        <p className="font-semibold text-primary my-4"><span className='font-semibold uppercase text-xl mr-2'>SAMU - BENIN</span> Centre de Réception et de Régulation des Appels</p>
        <p className="p-4">Veuillez entrer vos identifiants de connexion</p>
    </div>
    );

}

export default LoginFormHeader