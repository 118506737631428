import profile_bg from '../../assets/images/profile_bg.jpg'
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { Toast } from "primereact/toast";
import Loading from "../../components/Utils/Loading";
import { Icon } from '@iconify/react';
import default_avatar from "../../assets/images/default-user.jpg";
import FormModal from "../../components/modals/FormModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import { delete_medical_member, retrieve_medical_member, update_medical_member } from '../../services/management/users.service';
import UpdateMedicalMember from '../../components/forms/users/UpdateMedicalMember';


const roles_fr = {
    "admin" : "Directeur général",
    "arm" : "Assistant de régulation médicale",
    "company-director" : "Directeur de société",
    "branch-director" : "Directeur de branche",
    "chief-supervisor" : "Surveillant",
    "branch-supervisor" : "Superviseur de branche",
    "medical-regulator" : "Medecin régulateur",
    "ambulance-chief" : "Chef d'ambulance",
    "ambulance-member" : "Membre d'équipe d'intervention",
    "ambulance-driver" : "Chauffeur d'ambulance",
    "emergency-nurse" : "Urgentiste",
    "rescuer" : "Secouriste",
    "permanent-staff" : "Personnel permanant",
};

const MedicalMemberDetail =  () => {

    const {member_id} =  useParams()
    const [member, setMember] = useState(null)

    const toast = useRef(null);
    const [ready, setReady] = useState(false)

    const [btn_loading, setBtnLoading] = useState(false);

    const [update_visible, setUpdateVisible] = useState(false);
    const [delete_visible, setDeleteVisible] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        const get_member = async () => {
          try {
            const member_data = await retrieve_medical_member(member_id);
            setMember(member_data)
            setReady(true)
          } catch (error) {
            toast.current.show({severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000});
          }
        }
        get_member()
    }, [member_id])


    // Operations management
    const handle_update_member = async (event) => {
        event.preventDefault();
        const form_data = new FormData(event.target);
        const updated_data = Object.fromEntries(form_data.entries());
        setBtnLoading(true);
        if (!member_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de modification', 'Veuillez selectionner un membre du personnel médical');
            return;
        }
        try {
            const data = await update_medical_member(updated_data, member_id);
            const updated_member = data.results || data;
            setMember(updated_member);
            setBtnLoading(false);
            setUpdateVisible(false);
            handle_notification('success', 'Succès de la mise à jour', 'Informations modifiées avec succès');
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la mise à jour', e);
        }
    }

    const handle_delete_member = async () => {
        setBtnLoading(true);
        if (!member_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de suppression');
            return;
        }
        try {
            await delete_medical_member(member_id);
            setBtnLoading(false);
            setDeleteVisible(false);
            navigate(INTERNAL_ROUTES.ADMIN_BACKOFFICE + '/' + INTERNAL_ROUTES.MEDICAL_STAFF);
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la suppression', e.message);
        }
    }


    // Messsage management
    const handle_notification = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, life: 5000});
    }

    const update_member_preview = () => {
        setUpdateVisible(true);
    }

    const confirm_deletion = () => {
        setDeleteVisible(true);
    };
    const cancel_deletion = () => {
        setDeleteVisible(false);
    }

    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }

    if (!ready) {
        return (
            <>
                <Toast ref={toast} />
                <Loading />
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />

            <FormModal header={modal_header('Mettre à jour les informations d\'un membre du personnel médical')} children={<UpdateMedicalMember on_submit={handle_update_member} loading={btn_loading} data={member} />} visible={update_visible} setVisible={setUpdateVisible} />
            <ConfirmModal visible={delete_visible} setVisible={setDeleteVisible} title="Supprimer un membre du personnel médical" content="Voulez-vous vraiment supprimer ce member ?" icon="healthicons:warning" accept={() => {handle_delete_member() }}  reject={() => { cancel_deletion() }} />
            
            <section className="w-full relative py-8 px-4">
                <div className="relative h-[300px]">
                    <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: `url(${profile_bg})` }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
                    </div>
                </div>

                <div className="relative pt-64 bg-gray-200">
                    <div className="container mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-b -mt-64">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-center">
                                    <div className="relative w-full lg:w-3/12 lg:order-2 flex justify-center">
                                        <div className="relative">
                                            <img alt="Profil" src={default_avatar} className="shadow-xl rounded-full w-48 h-48 align-middle border-none absolute -m-16 -ml-20 lg:-ml-24 max-w-[200px]" />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                        <div className="py-6 px-3 mt-32 sm:mt-0">
                                            <span className="text-white py-2 px-4 text-center shadow rounded bg-blue-400">{roles_fr[member.groups[0].name || member.groups[0]]}</span>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                        <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                            <div className="mr-4 p-3 text-center">
                                                <span className="text-2xl xl xl:text-4xl font-bold block uppercase tracking-wide text-main-blue">{"-"}</span><span className="text-sm text-slate-600">Intrventions effectuées</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center my-12">
                                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800">
                                        {member.first_name + " " + member.last_name}
                                    </h3>
                                    
                                    <div className="flex flex-col justify-center pt-4 space-y-4 align-center">
                                        <span>Nom d'utilisateur : &nbsp; {member.username}</span>
                                        <span>Adresse électronique : &nbsp; {member.email}</span>
                                    </div>
                                </div>

                                <div className="flex justify-center items-center mt-8 xl:mt-12 mb-4 flex-wrap gap-4">
                                    <button onClick={() => update_member_preview()} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-secondary text-white shadow">
                                        <Icon icon="ic:round-edit" className="text-xl" />
                                        <span>Mettre à jour</span>
                                    </button>

                                    <button onClick={() => confirm_deletion()} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-red-500 text-white shadow">
                                        <Icon icon="ic:round-delete" className="text-xl" />
                                        <span>Retirer</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
        
    )
}

export default MedicalMemberDetail