import { useState, useEffect } from 'react';
import bg from '../assets/images/banner-bg/bg-5.jpg';

const ComingSoon = () => {
    const target_date = new Date("Aug 15, 2024 00:00:00").getTime();
    const [time_left, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0
    });

    useEffect(() => {
        const interval_id = setInterval(() => {
            const now = new Date().getTime();
            const distance = target_date - now;

            if (distance < 0) {
                clearInterval(interval_id);
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0
                });
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                
                setTimeLeft({ days, hours, minutes });
            }
        }, 1000);

        return () => clearInterval(interval_id);
    }, [target_date]);

    return (
        <div className="relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center" style={{ backgroundImage: `url(${bg})` }}>
            <div className="absolute inset-0 bg-primary opacity-40"></div>
            <div className="max-w-5xl mx-auto px-6 lg:px-8 flex flex-col items-center justify-center text-center z-10">
                <h2 className="text-4xl lg:text-5xl text-white font-bold mb-8">Fonctionnalité en cours d'implémentation</h2>
                <p className="text-white text-lg lg:text-xl leading-relaxed mb-12">
                    Cette fonctionnalité est en cours d'implémentation. Elle sera incluse dans une prochaine mise à jour. Nous mettons actuellement la touche finale à son développement.
                </p>
                <div className="flex flex-wrap items-center justify-center gap-4 p-2">
                    <div className="bg-white rounded-full px-6 py-2 min-w-[120px]">
                        <div className="font-bold text-xl text-gray-800">{time_left.days}</div>
                        <div className="text-xs uppercase text-gray-500">jour(s)</div>
                    </div>
                    <div className="bg-white rounded-full px-6 py-2 min-w-[120px]">
                        <div className="font-bold text-xl text-gray-800">{time_left.hours}</div>
                        <div className="text-xs uppercase text-gray-500">heure(s)</div>
                    </div>
                    <div className="bg-white rounded-full px-6 py-2 min-w-[120px]">
                        <div className="font-bold text-xl text-gray-800">{time_left.minutes}</div>
                        <div className="text-xs uppercase text-gray-500">minute(s)</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
