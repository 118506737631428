import { MultiSelect } from 'primereact/multiselect';

const CustomMultiSelect = ({ ...props }) => {
    return (
        <MultiSelect
            {...props}
            className="w-full border border-gray-400 rounded focus:ring-primary focus:border-primary"
        />
    );
}

export default CustomMultiSelect