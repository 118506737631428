import { GROUPS_DISPLAY } from "../../pages/common/constants";
import { MEDICAL_STAFF_URL } from "../urls";
import axios from './../customAxios';


export const get_medical_members = async (limit, offset, othersParams={}) => {
    try {
        const response = await axios.get(MEDICAL_STAFF_URL, {
        params: {
            limit: limit,
            offset: offset,
            ...othersParams
        }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};
export const getMembersOptions = (members) => (members.map(m => {
    let member = {...m}
    member.display = `${[m.first_name, m.last_name].join(" ")} (${m.groups.map(g => GROUPS_DISPLAY[g.name]).join(",")})`
    return member
}))

export const retrieve_medical_member = async (id) => {
    try {
        const response = await axios.get(`${MEDICAL_STAFF_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const create_medical_member = async (data) => {
    try {
        const response = await axios.post(MEDICAL_STAFF_URL, {
            'first_name': data.firstname,
            'last_name': data.lastname,
            'email': data.email,
            'username': data.username,
            'groups': [data.group],
            'is_active': data.is_active === "1" ? true : false
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_medical_member = async (updated_data, id) => {
    try {
        const response = await axios.put(`${MEDICAL_STAFF_URL}${id}/`, {
            'first_name': updated_data.firstname,
            'last_name': updated_data.lastname,
            'email': updated_data.email,
            'username': updated_data.username,
            'groups': [updated_data.group],
            'is_active': updated_data.is_active === "1" ? true : false
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
  }
};

export const delete_medical_member = async (id) => {
    try {
        const response = await axios.delete(`${MEDICAL_STAFF_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
