// import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import '../../assets/css/dashboard.css'
import AdminSidebar from '../../components/dashboard/AdminSidebar';
import HorizontalNavbar from '../../components/dashboard/HorizontalNavbar';

// const toggle_header_state = (body, collapsedClass) => {
//     body.classList.toggle(collapsedClass);
//       this.getAttribute("aria-expanded") === "true"
//         ? this.setAttribute("aria-expanded", "false")
//         : this.setAttribute("aria-expanded", "true");
//       this.getAttribute("aria-label") === "collapse menu"
//         ? this.setAttribute("aria-label", "expand menu")
//         : this.setAttribute("aria-label", "collapse menu");
// };
// const toggle_mobile_menu = (body) => {
//     body.classList.toggle("mob-menu-opened");
//       this.getAttribute("aria-expanded") === "true"
//         ? this.setAttribute("aria-expanded", "false")
//         : this.setAttribute("aria-expanded", "true");
//       this.getAttribute("aria-label") === "open menu"
//         ? this.setAttribute("aria-label", "close menu")
//         : this.setAttribute("aria-label", "open menu");
// };

const AdminBackOffice = () => {

    // useEffect(() => {
    //     const body = document.body;
    //     const collapseBtn = document.querySelector(".admin-menu .collapse-btn");
    //     const toggleMobileMenu = document.querySelector(".toggle-mob-menu");
    //     const collapsedClass = "collapsed";

    //     const toggleHeaderState = () => toggle_header_state(body, collapsedClass);
    //     const toggleMobileMenuState = () => toggle_mobile_menu(body);

    //     collapseBtn.addEventListener('click', toggleHeaderState);
    //     toggleMobileMenu.addEventListener('click', toggleMobileMenuState);

    //     return () => {
    //         collapseBtn?.removeEventListener('click', toggleHeaderState);
    //         toggleMobileMenu?.removeEventListener('click', toggleMobileMenuState);
    //     };
    // }, []);

    return (
        <div className='w-full h-full'>

            <HorizontalNavbar />

            <AdminSidebar />

            <main className="md:ml-64 min-h-screen h-auto pt-20 bg-gray-50">
                <Outlet />
            </main>

            <footer className="text-sm text-right p-2">
                &copy; { new Date().getFullYear() } Centre de Réception et de Régulation des Appels | SAMU BENIN
            </footer>
        </div>
    )
}

export default AdminBackOffice