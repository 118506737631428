import React from 'react'
import LoadingButton from '../../Utils/LoadingButton'

const UpdateProduct = ({on_submit, loading, data}) => {
  return (
    <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
        <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
                <div className="relative w-full mb-2 pr-2">
                    <label htmlFor="name" className="block mb-2 text-base font-medium text-gray-900">Nom du produit <span className="text-red-500">*</span> </label>
                    <input type="text" name="name" id="name" defaultValue={data.name} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
                    
                </div>
            </div>
            <div className="w-full lg:w-1/2">
                <div className="relative w-full mb-2 pr-2">
                    <label htmlFor="product_type" className="block mb-2 text-base font-medium text-gray-900">Type de produit <span className="text-red-500">*</span> </label>
                    <select id="product_type" name="product_type" defaultValue={data.product_type} className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring-primary w-full ease-linear transition-all duration-150" required>
                        <option value="medical-equipment">Équipement médical</option>
                        <option value="medication">Médicament</option>
                    </select>                    
                </div>
            </div>
        </div>
            
        <div className="my-4">
            <div className="relative w-full">
                <label htmlFor="description" className="text-lg text-gray-600">Description du produit </label>
                <textarea id="description" name="description" defaultValue={data.description} rows="4" className="block p-2 w-full text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-primary focus:border-primary" placeholder="Donnez une brève description de ce produit "></textarea>                                        
            </div>
        </div>

        <LoadingButton label="Enregistrer" loading= {loading} />
    </form>
  )
}

export default UpdateProduct
