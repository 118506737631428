import { useState } from "react";


const BooleanInput = (props) => {
  const {trueText="Oui", falseText="Non", checked = false, onChange, ...otherProps} = props
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange({ value: !isChecked });
  };

  return (
    <div
      className='flex items-start justify-start'
    >
      <div
        className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors duration-300 ${
          isChecked ? 'bg-blue-600' : 'bg-gray-300'
        }`}
        onClick={handleToggle}
      >
        <span
          className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300 ${
            isChecked ? 'translate-x-5' : 'translate-x-1'
          }`}
        />
      </div>
      <div className='ml-3'>{props.checked ? trueText : falseText}</div>
    </div>
  );
};

export default BooleanInput;
