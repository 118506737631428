import React, { useEffect, useState } from 'react';

import 'primereact/resources/primereact.min.css';

import Heading from '../../components/Utils/Heading.js';
import Loading from '../../components/Utils/Loading.js';
import { get_week_planning_members } from '../../services/management/planning.service.js';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Table } from 'flowbite-react';


const roles_fr = {
    "admin" : "Directeur général",
    "arm" : "Assistant de régulation médicale",
    "company-director" : "Directeur de société",
    "branch-director" : "Directeur de branche",
    "chief-supervisor" : "Surveillant",
    "branch-supervisor" : "Superviseur de branche",
    "medical-regulator" : "Medecin régulateur",
    "ambulance-chief" : "Chef d'ambulance",
    "ambulance-member" : "Membre d'équipe d'intervention",
    "ambulance-driver" : "Chauffeur d'ambulance",
    "emergency-nurse" : "Urgentiste",
    "rescuer" : "Secouriste",
    "permanent-staff" : "Personnel permanant",
};

const format_results = (results) => {
    const formatted_results = {};

    results.forEach(result => {
        const start_date = parseISO(result.start_date);
        const end_date = parseISO(result.end_date);
        const formatted_date = format(start_date, 'EEEE d MMMM', { locale: fr }).toUpperCase() + ' - ' + format(end_date, 'EEEE d MMMM', { locale: fr }).toUpperCase();

        if (!formatted_results[formatted_date]) {
            formatted_results[formatted_date] = {
                'permanence': [],
                'on_guard': []
            };
        }

        Object.keys(result.members).forEach(type => {
            Object.keys(result.members[type]).forEach(role => {
                result.members[type][role].forEach(member_data => {
                    const full_name = `${member_data.member.first_name} ${member_data.member.last_name}`;
                    const member_info = {
                        'name': full_name,
                        'role': roles_fr[role]
                    };
                    if (type === 'permanent') {
                        formatted_results[formatted_date].permanence.push(member_info);
                    } else if (type === 'on_guard') {
                        formatted_results[formatted_date].on_guard.push(member_info);
                    }
                });
            });
        });
    });

    console.log("Formatted results:", formatted_results);
    return formatted_results;
};

const AvailableMembers = () => {
    const [planning_members, setPlanningMembers] = useState([]);
    const [formatted_plannings, setFormattedPlannings] = useState({});

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState('');

    useEffect(() => {
        const fetch_data = async () => {
            try {
                const data = await get_week_planning_members();

                setPlanningMembers(data.results || data);
                setFormattedPlannings(format_results(data.results || data));
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetch_data();
    }, []);
   
    return (
        <>
           
            <div className="p-4 lg:p-8">
            <Heading title={"Planification mensuelle mois de " + format(new Date(), 'MMMM', { locale: fr }).toUpperCase()} />
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Loading />
                </div>
            ) : (
                <>
                    {error ? (
                        <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
                    ) : 
                    (
                        <>
                            {planning_members.length === 0 ? (
                                <p className="text-center my-8 text-xl font-semibold"> Aucune planification sur cette période !</p>
                            ) : 
                            (
                                <div className="overflow-x-auto my-8">
                                    <Table striped>
                                        <Table.Head>
                                        <Table.HeadCell className="font-semibold text-lg text-center">Période</Table.HeadCell>
                                        <Table.HeadCell className="font-semibold text-lg text-center">Disponibilité</Table.HeadCell>
                                        <Table.HeadCell className="font-semibold text-lg text-center">Personnel médical disponible</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y divide-x">
                                        
                                        {Object.entries(formatted_plannings).map(([date, data]) => (
                                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="border whitespace-nowrap font-semibold text-gray-900 dark:text-white">
                                                {date}
                                            </Table.Cell>
                                            <Table.Cell className="border whitespace-nowrap font-semibold text-base uppercase text-gray-900 dark:text-white">
                                                {data.permanence.length > 0 ? 'Permanence' : ''}{data.on_guard.length > 0 ? (data.permanence.length > 0 ? ' / ' : '') + 'De garde' : ''}
                                            </Table.Cell>
                                            <Table.Cell className="border">
                                                <ul className="list-disc space-y-2">
                                                    {data.permanence.map((member, index) => (
                                                        <li className="text-lg" key={index} >
                                                            <span className='mr-4 font-semibold'>{member.name}</span>
                                                            <span className='text-sm'>{member.role} (Permanence)</span>
                                                        </li>
                                                    ))}
                                                    {data.on_guard.map((member, index) => (
                                                        <li className="text-lg" key={index} >
                                                            <span className='mr-4 font-semibold'>{member.name}</span>
                                                            <span className='text-sm'>{member.role} (De garde)</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Table.Cell>
                                            </Table.Row>
                                        ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            </div>
        </>
    );
};

export default AvailableMembers;
