import { useParams } from "react-router-dom";
import InterventionFormEditor from "./InterventionFormEditor"


const NewIntervention = () => {

    const {intervention_id} = useParams();
    return (
        <div className="px-4 w-full">
            <InterventionFormEditor  interventionId={intervention_id}/>
        </div>
    );
}

export default NewIntervention
