import { INTERVENTION_AMBULANCE_URL, INTERVENTION_URL } from "../urls";
import axios from '../customAxios';
import { Groups } from "../../pages/common/constants";

export const create_intervention = async () => {
    try {
        const response = await axios.post(INTERVENTION_URL, {
            'status': 'not-started'
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const retrieve_intervention = async (id) => {
    try {
        const response = await axios.get(INTERVENTION_URL + `${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const update_intervention_status = async (id, status) => {
    try {
        const response = await axios.patch(INTERVENTION_URL + `${id}/`, {
            'status': status
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const update_critical_intervention_status = async (id, data) => {
    try {

        const response = await axios.patch(INTERVENTION_URL + `${id}/`, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const get_user_interventions = async () => {
    try {
        const response = await axios.get(INTERVENTION_URL);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

export const get_interventions_ambulances = async () => {
    try {
        const response = await axios.get(INTERVENTION_AMBULANCE_URL);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
}

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};


export const getInterventionAmbulanceChief = (intervention) => {
    let interventionAmbulance = intervention.ambulances[0]
    for (let member of (interventionAmbulance?.staff_members || [])) {
        console.log("-----member ", member.staff_member.groups.some(g => g.name === Groups.AMBULANCE_CHIEF.value))
        if (member.staff_member.groups.some(g => g.name === Groups.AMBULANCE_CHIEF.value)) {
            return (
                [member.staff_member.first_name, member.staff_member.last_name].join(" ")
                || member.staff_member.username
            )
        }
    }
    return "Non-défini"
}
