import { ConfirmDialog } from "primereact/confirmdialog";

const ConfirmModal = ({visible, setVisible, title, content, accept, reject}) => {

    const hide = () => {
        setVisible(false);
    };

    return (
        <ConfirmDialog
            visible={visible}
            onHide={hide}
            message={content}
            icon='pi pi-info-circle'
            header={title}
            accept={accept}
            reject={reject}
            acceptLabel="Confirmer"
            rejectLabel="Annuler"
            acceptClassName="w-fit px-4 py-2 rounded bg-transparent text-red-500 border border-red-500 text-sm hover:bg-red-500 hover:text-white"
            rejectClassName="w-fit px-4 py-2 rounded bg-secondary text-white text-sm mr-4"
        />
    );
};

export default ConfirmModal;
