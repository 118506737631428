import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import { INTERNAL_ROUTES } from '../../utils/internal_routes.js';
import { create_ambulance, update_ambulance, delete_ambulance, get_ambulances } from '../../services/management/ambulances.service.js';

import CardStatOne from '../../components/cards/stats/CardStatOne.js';
import Heading from './../../components/Utils/Heading';
import Loading from '../../components/Utils/Loading.js';
import FormModal from './../../components/modals/FormModal';
import NewAmbulance from './../../components/forms/ambulances/NewAmbulance';
import ConfirmModal from '../../components/modals/ConfirmModal.js';
import UpdateAmbulance from '../../components/forms/ambulances/UpdateAmbulance.js';


const AmbulancesList = () => {
    const [ambulances, setAmbulances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btn_loading, setBtnLoading] = useState(false);

    const [error, setError] = useState('');

    const [selected_id, setSelectedId] = useState(null);
    const [selected_ambulance, setSelectedAmbulance] = useState({});

    const [add_visible, setAddVisible] = useState(false);
    const [update_visible, setUpdateVisible] = useState(false);
    const [delete_visible, setDeleteVisible] = useState(false);

    const toast = useRef(null);


    useEffect(() => {
        const fetch_data = async () => {
            try {
                // FIXME: Pagination
                const data = await get_ambulances(1000, 0);
                setAmbulances(data.results || data);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetch_data();
    }, []);

    // Operations management
    const handle_add_ambulance = async (event) => {
        event.preventDefault();
        setBtnLoading(true);
        const form_data = new FormData(event.target);
        const ambulance = Object.fromEntries(form_data.entries());
        try {
            const data = await create_ambulance(ambulance);
            setAmbulances([...ambulances, data.results || data]);
            setAddVisible(false);
            setBtnLoading(false);
            handle_notification('success', 'Succès de l\'ajout', 'Ambulance ajoutée avec succès');
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de l\'ajout', e.message || e);
        }
    }

    const handle_update_ambulance = async (event) => {
        event.preventDefault();
        const form_data = new FormData(event.target);
        const updated_data = Object.fromEntries(form_data.entries());
        setBtnLoading(true);
        if (!selected_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de modification', 'Veuillez selectionner une ambulance');
            return;
        }
        try {
            const data = await update_ambulance(updated_data, selected_id);
            const updated_ambulance = data.results || data;
            const updated_ambulances = ambulances.map(amb => amb.id === selected_id ? updated_ambulance : amb);
            setAmbulances(updated_ambulances);
            setUpdateVisible(false);
            setBtnLoading(false);
            handle_notification('success', 'Succès de la mise à jour', 'Ambulance modifiée avec succès');
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la mise à jour', e);
        }
    }

    const handle_delete_ambulance = async () => {
        setBtnLoading(true);
        if (!selected_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de suppression', 'Veuillez selectionner une smarty');
            return;
        }
        try {
            await delete_ambulance(selected_id);
            const remaining_ambulances = ambulances.filter(amb => amb.id !== selected_id);
            setAmbulances(remaining_ambulances);
            setDeleteVisible(false);
            setBtnLoading(false);
            handle_notification('success', 'Succès de la suppression', 'Ambulance supprimée avec succès');
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la suppression', e.message);
        }
    }


    // Messsage management
    const handle_notification = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, life: 5000});
    }


    // Modal handler : 
    const add_ambulance_preview = () => {
        setAddVisible(true);
    }

    const update_ambulance_preview = (ambulance) => {
        setUpdateVisible(true);
        setSelectedId(ambulance.id);
        setSelectedAmbulance(ambulance);
    }

    const confirm_deletion = (ambulance_id) => {
        setDeleteVisible(true);
        setSelectedId(ambulance_id);
    };
    const cancel_deletion = () => {
        setDeleteVisible(false);
    }

    const statuses_fr = {
        'available': 'Disponible',
        'spoiled': 'Indisponible',
    }
    const qualifications_fr = {
        'level-1': 'Type A',
        'level-2': 'Type B',
        'level-3': 'Type C',
    }

    // Modal headers
    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }

    // DataTable columns template
    const status_template = (ambulance) => {
        return <Tag value={statuses_fr[ambulance.status]} severity={get_severity(ambulance.status)}></Tag>;
    };
    const qualifications_template = (ambulance) => {
        return qualifications_fr[ambulance.qualifications[0]]
    };

    const action_template = (row_data) => {
        return (
            <div className="flex justify-center gap-2 items-center">
                <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.AMBULANCE_DETAIL}/${row_data.id}`} className="flex justify-start gap-2 hover:-translate-y-1"> <Icon icon={'ph:list-magnifying-glass'} className="text-gray-600 xl:text-lg" /></NavLink>

                <Icon onClick={() => update_ambulance_preview(row_data)} className="xl:text-lg text-sky-500 cursor-pointer hover:-translate-y-1" icon={'eva:edit-2-fill'} />

                <Icon onClick={() => confirm_deletion(row_data.id)} className="xl:text-lg text-red-500 cursor-pointer hover:-translate-y-1" icon={'eva:trash-2-outline'} />
        </div>
        );
    }

    const get_severity = (status) => {
        switch (status) {
            case 'available':
                return 'success';

            case 'spoiled':
                return 'warning';

            default:
                return null;
        }
    };


    return (
        <>
            <FormModal header={modal_header('Ajouter une ambulance')} children={<NewAmbulance on_submit={handle_add_ambulance} loading={btn_loading} />} visible={add_visible} setVisible={setAddVisible} />
            <FormModal header={modal_header('Mettre à jour les informations d\'une ambulance')} children={<UpdateAmbulance on_submit={handle_update_ambulance} loading={btn_loading} data={selected_ambulance} />} visible={update_visible} setVisible={setUpdateVisible} />
            <ConfirmModal visible={delete_visible} setVisible={setDeleteVisible} title="Supprimer une ambulance" content="Voulez-vous vraiment supprimer cette ambulance ?" icon="healthicons:warning" accept={() => {handle_delete_ambulance() }}  reject={() => { cancel_deletion() }} />


            <Toast ref={toast} />


            {/* Page content */}
            <div className="p-4 lg:p-8">
            <Heading title="Liste des ambulances" />
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Loading />
                </div>
            ) : (
                <>
                    {error ? (
                        <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
                    ) : 
                    (
                        <>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center my-8">
                                    <CardStatOne icon="mdi:ambulance" title="Ambulance(s)" value={ambulances.length} bg_color="bg-primary" />
                                </div>
                                <div className="flex justify-end items-center my-4">
                                    <button onClick={() => add_ambulance_preview()} className="flex justify-center items-center gap-2 px-4 py-2 rounded bg-secondary text-white shadow">
                                        <Icon icon="mi:add" className="text-xl" />
                                        <span>Ajouter</span>
                                    </button>
                                </div>
                            </div>
                            

                            
                            {ambulances.length === 0 ? (
                                <p className="text-center my-8 text-xl font-semibold"> Aucune ambulance enregistrée !</p>
                            ) : 
                            (
                                <div className="card my-4">
                                    <DataTable value={ambulances} showGridlines={true} sortMode="multiple" removableSort paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                                        <Column field="identifier" header="N&deg; d'identification" style={{ width: '25%' }}></Column>
                                        <Column field="qualifications" header="Classe" body={qualifications_template} sortable style={{ width: '20%' }}></Column>
                                        <Column field="status" header="Disponibilité" body={status_template} style={{ width: '15%' }}></Column>
                                        <Column field="description" header="Description" style={{ width: '35%' }}></Column>
                                        <Column header="Action" body={action_template} style={{ width: '5%' }}></Column>
                                    </DataTable>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            </div>
        </>
    );
};

export default AmbulancesList;
