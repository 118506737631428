import { MEDICAL_COVERS_URL } from "../urls";
import axios from './../customAxios';


export const get_medical_covers = async (limit, offset, othersParams={}) => {
    try {
        const response = await axios.get(MEDICAL_COVERS_URL, {
        params: {
            limit: limit,
            offset: offset,
            ...othersParams
        }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const retrieve_medical_cover = async (id) => {
    try {
        const response = await axios.get(`${MEDICAL_COVERS_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};


const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
