export const INTERNAL_ROUTES = {
    LOGIN: '/',
    FORBIDDEN: '/app/forbidden',
    ADMIN_BACKOFFICE: '/app/administration',
    ADMIN_DASHBOARD: 'app/dashboard',
    STAFF_BACKOFFICE: '/app/personnel-medical',
    STAFF_DASHBOARD: 'app/dashboard',
    PATIENT_DASHBOARD: 'app/patient',

    INTERVENTIONS: 'app/interventions',

    ADMIN_INTERVENTIONS: 'app/interventions',
    ADMIN_INTERVENTION_FORM: 'app/interventions/formulaire-intervention',
    ADMIN_INTERVENTION_SUMMARY: 'app/interventions/vue-sommaire-intervention',
    ADMIN_INTERVENTION_MAP : 'app/interventions/suivi-intervention',

    PATIENTS: 'app/patients',
    PATIENTS_DETAIL: 'app/patients/:id',
    ADMIN_PATIENTS: 'app/patients',
    ADMIN_PATIENTS_DETAIL: 'app/patients/:id',

    PLANIFICATION: 'app/planification',

    KPI_STATS: 'app/statistiques/kpi-principaux',
    INTERVENTIONS_STATS: 'app/statistiques/interventions',
    GEOGRAPHICAL_STATS: 'app/statistiques/repartition-geographique',
    PERFORMANCE_STATS: 'app/statistiques/performance-equipe',
    TREND_STATS: 'app/statistiques/tendances-et-evolution',
    
    FINANCES: 'app/finances',

    TEAMS: 'app/equipes-de-permanence',
    ADMIN_TEAMS: 'app/equipes',

    INTERVENTION_FORM: 'app/interventions/formulaire-intervention',
    INTERVENTION_SUMMARY: 'app/interventions/vue-sommaire-intervention',
    INTERVENTION_MAP : 'app/interventions/suivi-intervention',

    TEAMS_DETAIL: 'app/equipes-de-permanence/:id',
    ADMIN_TEAMS_DETAIL: 'app/equipes/:id',

    MEDICAL_STAFF: 'app/gestion-du-personnel-medical',
    MEDICAL_STAFF_DETAIL: 'app/vue-detaillee-personnel-medical',

    STOCK_MATERIALS: 'app/stock-et-materiel',
    STOCK_MATERIALS_DETAIL: 'stock-et-materiel/:id',

    AMBULANCES: 'app/gestion-des-ambulances',
    AMBULANCE_DETAIL: 'app/gestion-des-ambulances/detail-ambulance',

    LIBRARY: 'app/base-de-connaissances',
    ADMIN_LIBRARY: 'app/bibliotheque',

    NOTIFICATION: 'app/notifications',
    ADMIN_NOTIFICATION: 'app/notifications',

    SETTINGS: 'app/parametres',

    MEDICAL_COVERS: 'app/couvertures médicales',
    MEDICAL_COVERS_DETAIL: 'app/couvertures-medicales/detail-couverture-medicale',
  };