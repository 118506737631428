import { Timeline } from "primereact/timeline";
import AmbulanceTrackingMap from "../../components/interventions/AmbulanceTrackingMap";
import { Icon } from '@iconify/react';
import { useContext, useEffect, useRef, useState } from "react";
import { retrieve_intervention, update_critical_intervention_status, update_intervention_status } from "../../services/interventions/intervention.service";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Utils/Loading";
import { UserContext } from "../../context/UserContext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import ConfirmUpdate from "../../components/modals/ConfirmUpdate";
import FormModal from "../../components/modals/FormModal";
import InterventionJustification from "../../components/forms/interventions/Justification";
import useInterventionAmbulanceSocket from "./hooks/useInterventionAmbulanceSocket";
import { Groups } from "./constants";


const step_statuses = [
  { key: 'team-formed', index: 1 },
  { key: 'ambulance-on-going', index: 2 },
  { key: 'ambulance-arrived', index: 3 },
  { key: 'ambulance-returned', index: 4 },
  { key: 'done', index: 5 }
];

const get_steps = (intervention) => {
  const steps = [
    { status: 'Equipe affectée', icon: 'mdi:outline-circle', completed: false },
    { status: 'En route', icon: 'mdi:outline-circle', completed: false },
    { status: 'Intervention en cours', icon: 'mdi:outline-circle', completed: false },
    { status: 'Intervention terminée', icon: 'mdi:circle-outline', completed: false },
    { status: 'Retour à la base', icon: 'mdi:circle-outline', completed: false },
    { status: 'Effectuée', icon: 'mdi:circle-outline', completed: false }
  ];

  const status_index = step_statuses.find(status => status.key === intervention.status)?.index || -1;
  return steps.map((step, index) => ({
    ...step,
    icon: (index + 1) <= status_index ? 'mdi:check-circle' : 'mdi:circle-outline',
    completed: (index + 1) <= status_index
  }));
};

const InterventionMap = () => {

  const [intervention, setIntervention] = useState(null)
  const [ambulanceTeamChiefGeoPosition, setAmbulanceTeamChiefGeoPosition] = useState(null)
  const [interventionAmbulanceId, setInterventionAmbulanceId] = useState(null)
  const [userIsAmbulanceChief, setUserIsAmbulanceChief] = useState(false)
  const [steps, setSteps] = useState([])
  const [ready, setReady] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [dialog_visible, setDialogVisible] = useState(false);
  const [justif_visible, setJustifVisible] = useState(false);
  const [btn_loading, setBtnLoading] = useState(false);

  const [new_status, setNewStatus] = useState('');
  const { intervention_id } = useParams()
  const [
    interventionAmbulanceSocketMessage,
    interventionAmbulanceSocket
  ] = useInterventionAmbulanceSocket({ interventionAmbulanceId, isAmbulanceTeamChief: userIsAmbulanceChief })

  const toast = useRef(null);

  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const has_role = (required_roles) => {
    return user.groups.some(group => required_roles.includes(group.name));
  };

  const go_back_to_form = () => {
    try {
      navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_FORM}/${intervention_id}`);
    } catch (e) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Il n'est pas possible d'accéder au formulaire. Une erreur est survenue.", life: 5000 });
    }
  };

  useEffect(() => {
    const get_intervention = async () => {
      try {
        const intervention_data = await retrieve_intervention(intervention_id);
        setIntervention(intervention_data)
        // For now, one intervention is one ambulance
        let interventionAmbulance = intervention_data.ambulances[0]
        if (interventionAmbulance) {
          setInterventionAmbulanceId(interventionAmbulance.id)
          if (interventionAmbulance.staff_members) {
            let teamChiefs = interventionAmbulance.staff_members.filter(member => (
              member.staff_member.groups.some(g => g.name === Groups.AMBULANCE_CHIEF.value)
            ))
            if (teamChiefs?.length) {
              setUserIsAmbulanceChief(teamChiefs[0].staff_member.id === user.id)
            }
          }
        }
      } catch (error) {
        console.log("-----error ", error)
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000 });
      }
    }

    get_intervention()
  }, [intervention_id])

  useEffect(() => {
    console.log("-----interventionAmbulanceSocketMessage ", interventionAmbulanceSocketMessage)
    if (interventionAmbulanceSocketMessage?.intervention_status && new_status !== interventionAmbulanceSocketMessage.intervention_status) {
      let _intervention = JSON.parse(JSON.stringify(intervention))
      _intervention.status = interventionAmbulanceSocketMessage.intervention_status
      on_update_status(_intervention)
    }
    else if (interventionAmbulanceSocketMessage?.geo_position) {
      setAmbulanceTeamChiefGeoPosition(interventionAmbulanceSocketMessage?.geo_position)
    }
  }, [interventionAmbulanceSocketMessage])

  useEffect(() => {
    if (intervention) setSteps(get_steps(intervention))
    setReady(true)
  }, [intervention])

  // Intervention state management
  const update_status = async () => {
    try {
      const intervention_data = await update_intervention_status(intervention_id, new_status);
      on_update_status(intervention_data)
    } catch (error) {
      console.log("-----error ", error)
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la mise à jour du statut de l'intervention.", life: 5000 });
    }
  }

  const on_update_status = (intervention_data) => {
    setIntervention(intervention_data)
    toast.current.show({severity: 'success', summary: 'Statut mis à jour', detail: 'Statut de l\'intervention mis à jour.'});
    setDialogVisible(false);
    interventionAmbulanceSocket.send(JSON.stringify({ "intervention_status": new_status }))
    if (new_status === "done" || new_status === "ambulance-returned" || new_status === "white-out" || new_status === "canceled") {
      navigate(INTERNAL_ROUTES.STAFF_BACKOFFICE + '/' + INTERNAL_ROUTES.STAFF_DASHBOARD);
    }
  }

  const update_critical_status = async (event) => {
    try {
      event.preventDefault();
      let data = {};
      if (new_status === 'canceled') {
        data = {
          'cancel_justification': event.target.cancel_justification.value,
          'status': new_status
        }
      } else {
        data = {
          'white_out_justification': event.target.white_out_justification.value,
          'status': new_status
        }
      }
      console.log("data", data)
      const intervention_data = await update_critical_intervention_status(intervention_id, data);
      setIntervention(intervention_data)
      setBtnLoading(false);
      setJustifVisible(false);
      toast.current.show({severity: 'success', summary: 'Statut mis à jour', detail: 'Statut de l\'intervention mis à jour.'});
      if (new_status === "done" || new_status === "ambulance-returned" || new_status === "white-out" || new_status === "canceled") {
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_SUMMARY}/${intervention_id}`);
      }
    } catch (error) {
      console.log("-----error ", error)
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la mise à jour du statut de l'intervention.", life: 5000 });
    }
  }

  const handle_status_change = (new_state) => {
    setNewStatus(new_state)
    if (['canceled', 'white-out'].includes(new_state)) {
      setJustifVisible(true)
    } else {
      setDialogVisible(true)
    }
  }

  const customMarker = (item) => {
    return (
      <Icon
        icon={item.icon}
        className={`text-xl ${item.completed ? 'text-teal-500' : 'text-gray-400'}`}
      />
    );
  };

  const customContent = (item) => {
    return (
      <div>
        <h3 className="font-bold">{item.status}</h3>
      </div>
    );
  };


  if (!intervention || !ready) {
    return (
      <div className="my-8 p-4">
        <Loading />
      </div>
    )
  }

  return (
    <div className="mb-8 px-4">
      <ConfirmUpdate visible={dialog_visible} setVisible={setDialogVisible} title="Confirmer le changement de statut" content="Voulez-vous vraiment changer le statut de cette intervention ?" icon="healthicons:warning" accept={() => { update_status() }} reject={() => { setDialogVisible(false) }} />
      <FormModal header={<p>Justification</p>} visible={justif_visible} setVisible={setJustifVisible} children={<InterventionJustification on_submit={update_critical_status} type={new_status} loading={btn_loading} />} />
      <Toast ref={toast} />
      <div className="w-full flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Intervention N° {intervention.id}</h1>
      </div>
      {/* <div className="w-full timeline-section container mb-8 lg:mb-12"> */}
      <div className="mb-4 w-full mx-auto">
        <Timeline
          value={steps}
          layout="horizontal"
          marker={customMarker}
          content={customContent}
          align="alternate"
          className="w-full"
        />
      </div>

      <div className="w-full flex justify-end gap-2 items-center my-4 lg:my-8">
        {has_role(['ambulance-chief', 'ambulance-driver', 'ambulance-member']) ?
          (
            <>
              {intervention.status === "team-formed" && (
                <Button
                  className="bg-secondary text-white px-3 py-2 text-sm"
                  label="Marquer départ"
                  onClick={() => handle_status_change("ambulance-on-going")}
                />
              )}
              {intervention.status === "ambulance-on-going" && (
                <>
                  <Button
                    className="bg-secondary text-white px-3 py-2 text-sm"
                    label="Marquer arrivée"
                    onClick={() => handle_status_change("ambulance-arrived")}
                  />
                  <Button
                    className="bg-red-500 text-white px-3 py-2 text-sm"
                    label="Sortie blanche"
                    onClick={() => handle_status_change("white-out")}
                  />

                </>
              )}
              {intervention.status === "ambulance-arrived" && (
                <Button
                  className="bg-secondary text-white px-3 py-2 text-sm"
                  label="Fin de l'intervention"
                  onClick={() => handle_status_change("ambulance-returned")}
                />
              )}
            </>
          ) :
          (
            (has_role(['arm']) &&
              <>
                {intervention.status === "team-formed" && (
                  <Button
                    className="bg-yellow-400 text-white px-3 py-2 text-sm"
                    label="Sortie décommandée"
                    onClick={() => handle_status_change("canceled")}
                  />
                )}
                {intervention.status === "ambulance-returned" && (
                  <Button
                    className="bg-teal-500 text-white px-3 py-2 text-sm"
                    label="Sortie effectuée"
                    onClick={() => handle_status_change("done")}
                  />
                )}
              </>
            )
          )}
        <Button
          className="bg-gray-500 text-white px-3 py-2 text-sm"
          label="Retour au formulaire"
          onClick={go_back_to_form}
        />
      </div>
      {/* </div> */}
      < AmbulanceTrackingMap
        interventionAmbulanceId={interventionAmbulanceId}
        geoPosition={ambulanceTeamChiefGeoPosition}
      />
    </div>
  );
}

export default InterventionMap
