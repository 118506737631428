import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { useEffect, useRef, useState } from "react"
import { retrieve_medical_cover } from "../../services/management/medical_covers.service"
import { useParams } from "react-router-dom"
import Loading from "../../components/Utils/Loading"

const MedicalCoverDetail = () =>{
    const {medical_cover_id} =  useParams()
    const [medical_cover, setMedicalCover] = useState(null)

    const toast = useRef(null);
    const [ready, setReady] = useState(false)

    const types = [
        "Couverture d'urgence",
        "Couverture pour consultations préventives",
        "Couverture pour transport médicalisé",
        "Couverture pour événements spéciaux (mariages, concerts, etc.)",
        "Couverture pour soins palliatifs"
    ];

    useEffect(() => {
        const get_ambulance = async () => {
          try {
            const data = await retrieve_medical_cover(medical_cover_id);
            setMedicalCover(data)
            setReady(true)
            console.log("data", data)

          } catch (error) {
            console.log("-----error ", error)
            toast.current.show({severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000});
          }
        }
        get_ambulance()
    }, [medical_cover_id])

    if(!ready){
        return <Loading />
    }

    return (
        <div>
            <div className="mb-8 text-center py-4">
                <h2 className="cover-title text-secondary text-2xl lg:text-3xl uppercase font-semibold">Couverture médicale N° {medical_cover.id}</h2>
            </div>

            <form className="w-full" onSubmit={(e) => e.preventDefault()}>
            <div className="w-full lg:w-3/4 2xl:w-2/3 mx-auto flex flex-col justify-center items-center gap-4 md:gap-8 px-4 md:px-8">
                <div className="w-full">
                    <label htmlFor="name" className="text-base mb-2">Nom & Prénom(s)</label>
                    <InputText id="name" name="name" value={medical_cover.first_name} className="py-2 px-4 border border-gray-200 w-full shadow-none" readonly/>
                </div>

                <div className="flex flex-wrap justify-center items-center w-full mb-4 gap-4 lg:gap-0">
                    <div className="w-full lg:w-1/2 lg:pr-4">
                        <label htmlFor="email" className="text-base mb-2">Adresse mail</label>
                        <InputText id="email" name="email" value={medical_cover.email} className="py-2 px-4 border border-gray-200 w-full shadow-none" readonly/>
                    </div>

                    <div className="w-full lg:w-1/2">
                        <label htmlFor="phone" className="text-base mb-2">N° de téléphone</label>
                        <InputText id="phone" name="phone" value={medical_cover.phone_number} className="py-2 px-4 border border-gray-200 w-full shadow-none" readonly/>
                    </div>
                </div>

                <div className="flex flex-wrap justify-center items-center w-full mb-4 gap-4 lg:gap-0">
                    <div className="w-full lg:w-1/2 lg:pr-4">
                        <label htmlFor="type" className="text-base mb-2">Type de couverture</label>
                        <select id="type" name="type" className="py-2.5 px-4 border border-gray-200 w-full rounded" readonly>
                            {types.map((type, index) => (
                                <option key={index} value={type}>{type.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>

                    <div className="w-full lg:w-1/2">
                        <label htmlFor="nth_people" className="text-base mb-2">Estimation du nombre de personnes</label>
                        <input type="number" value={medical_cover.people_count} id="nth_people" name="nth_people" min={3} className="py-2 px-4 border border-gray-200 w-full rounded" readonly/>
                    </div>
                </div>

                <div className="flex flex-wrap justify-center items-center w-full mb-4 gap-4 lg:gap-0">
                    <div className="w-full lg:w-1/2 lg:pr-4">
                        <label htmlFor="start_date" className="text-base mb-2">Date de début de la couverture</label>
                        <input type="date" value={new Date(medical_cover.start_date).toISOString().split('T')[0]} id="start_date" name="start_date" className="py-2 px-4 border border-gray-200 w-full rounded" readonly/>
                    </div>

                    <div className="w-full lg:w-1/2">
                        <label htmlFor="duration" className="text-base mb-2">Durée en jours de la couverture</label>
                        <input type="number" value={medical_cover.duration} id="duration" name="duration" min={1} className="py-2 px-4 border border-gray-200 w-full rounded" readonly/> 
                    </div>
                </div>

                <div className="w-full">
                    <label htmlFor="description" className="text-base mb-2">Description de la couverture</label>
                    <InputTextarea id="description" value={medical_cover.description} name="description" rows={5} cols={30} className="py-2 px-4 border border-gray-200 w-full" readonly/>
                </div>
                
                <div className="w-full">
                    <label htmlFor="indication" className="text-base mb-2">Indication du lieu de la couverture</label>
                    <InputTextarea id="indication" value={medical_cover.location} name="indication" rows={5} cols={30} className="py-2 px-4 border border-gray-200 w-full" readonly/>
                </div>

            </div>

        </form>
        </div>
    )
}
export default MedicalCoverDetail