import { NavLink } from "react-router-dom";
import { Icon } from '@iconify/react';

const BottomNavigation = () => {

    return (
        <div className="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
            <NavLink to="/app/profil-utilisateur" data-tooltip-target="tooltip-profile" className="bg-secondary text-white px-3 py-2 inline-flex justify-center rounded cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                <Icon icon="icomoon-free:profile" className="text-base xl:text-lg" />
            </NavLink>
            <div id="tooltip-profile" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-secondary rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">Profil</div>
          

            <NavLink to="/app/notifications-admin" data-tooltip-target="tooltip-notifs" className="bg-secondary text-white px-3 py-2 inline-flex justify-center rounded cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                <Icon icon="mi:notification" className="text-base xl:text-lg" />
            </NavLink>
            <div id="tooltip-notifs" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-secondary rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                Notifications
            </div>

            <span data-tooltip-target="tooltip-notifs" className="bg-secondary text-white px-3 py-2 inline-flex justify-center rounded cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                <Icon icon="tabler:logout" className="text-base xl:text-lg" />
            </span>
            <div id="tooltip-logout" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-secondary rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                Déconnexion
            </div>
        </div>
    );

}

export default BottomNavigation