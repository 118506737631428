import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
// import { MultiSelect } from 'primereact/multiselect';
import { create_intervention_ambulance, get_intervention_ambulances, update_intervention_ambulance } from '../../services/management/intervention_ambulance.service';
import { get_medical_members, getMembersOptions } from '../../services/management/users.service';
import { get_ambulances } from '../../services/management/ambulances.service';
import { bulk_create_intervention_member, bulk_delete_intervention_member } from '../../services/management/intervention_team_member.service';
import { PickList } from 'primereact/picklist';
import { Toast } from 'primereact/toast';
import { Groups, NotificationLevel, NotificationRequestType, NotificationType } from '../common/constants';
import useNotificationSocket from '../common/hooks/useNotificationSocket';


const InterventionAmbulanceManager = ({ interventionId, interventionStatus }) => {
  const [notification, notificationSocket] = useNotificationSocket();

  const [staffMembers, setStaffMembers] = useState([])
  const [availableAmbulances, setAvailableAmbulances] = useState([])
  const [selectedAmbulance, setSelectedAmbulance] = useState(null)
  const [selectedMembersIdsBeforeSave, setSelectedMembersIdsBeforeSave] = useState([])
  // const [selectedStaffMembers, setSelectedStaffMembers] = useState([])

  const [staffMembersSource, setStaffMembersSource] = useState([])
  const [staffMembersTarget, setStaffMembersTarget] = useState([])

  const [loading, setLoading] = useState(false)
  const [interventionAmbulanceId, setInterventionAmbulanceId] = useState(null)
  const [userMapStaffMemberId, setUserMapStaffMemberId] = useState(null)
  const [stateIsReady, setStateIsReady] = useState(false)

  const toast = useRef(null);

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      try {
        const response = await get_intervention_ambulances({intervention: interventionId});
        // FIXME: For now we deal with only one ambulance. Update the logic to init data
        // for each ambulance if there are many later
        if (response.results.length) {
          let data = response.results[0]
          setSelectedAmbulance({...data.ambulance})
          setInterventionAmbulanceId(data.id)
          let _userIdMapStaffMemberId = {}
          // setSelectedStaffMembers(getMembersOptions(data.staff_members.map(item => ({
          //   ...item.staff_member,
          // }))))
          setStaffMembersTarget(getMembersOptions(data.staff_members.map(item => ({
            ...item.staff_member,
          }))))

          setSelectedMembersIdsBeforeSave(data.staff_members.map(item => item.staff_member.id))

          for (let item of data.staff_members) {
            _userIdMapStaffMemberId[item.staff_member.id] = item.id
          }
          setUserMapStaffMemberId(_userIdMapStaffMemberId)
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    const setStaffMembersData = async () => {
      setLoading(true);
      try {
        // FIXME: get all, and auto-complete + pagination
        const response = await get_medical_members(100, 0, {available: true, intervention: interventionId});
        const availableMembers = getMembersOptions(response.results)
        setStaffMembers(availableMembers)

        const selectedIds = new Set(staffMembersTarget.map(item => item.id));

        const newSource = availableMembers.filter(m => !selectedIds.has(m.id));
        // const newTarget = availableMembers.filter(m => selectedIds.has(m.id));

        setStaffMembersSource(newSource)
        // setStaffMembersTarget(newTarget)

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    const setAvailableAmbulancesData = async () => {
      setLoading(true);
      try {
        // FIXME: get all, and auto-complete + pagination
        const response = await get_ambulances(100, 0, {available: true, intervention: interventionId});
        setAvailableAmbulances(response.results)
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    initData()
    setAvailableAmbulancesData();
    setStaffMembersData();
  }, []);

  useEffect(() => {
    if(!stateIsReady){
      if (availableAmbulances?.length && staffMembers.length && staffMembersSource.length && staffMembersTarget) setStateIsReady(true)
    }
  }, [availableAmbulances, staffMembers, staffMembersSource, staffMembersTarget, stateIsReady])

  const save = async () => {
    const beforeSaveSelectedUsersSet = new Set(selectedMembersIdsBeforeSave);
    const currentSelectedUsersSet = new Set(staffMembersTarget.map(item => item.id));
    const addedIds = [...currentSelectedUsersSet].filter(id => !beforeSaveSelectedUsersSet.has(id));
    const removedIds = [...beforeSaveSelectedUsersSet].filter(id => !currentSelectedUsersSet.has(id));
    if (!addedIds.length && !removedIds.length) return

    setLoading(true);
    try {
      if (removedIds.length) {
        await bulk_delete_intervention_member(removedIds.map(id => ({id: userMapStaffMemberId[id]})));
      }
      if (addedIds.length) {
        const results = await bulk_create_intervention_member(addedIds.map(id => ({
          intervention_ambulance: interventionAmbulanceId,
          staff_member: id
        })));
        let _userIdMapStaffMemberId = {...userMapStaffMemberId}
        for (let item of results) {
          _userIdMapStaffMemberId[item.staff_member] = item.id
        }
        setUserMapStaffMemberId(_userIdMapStaffMemberId)
      }

      // setSelectedMembersIdsBeforeSave(selectedStaffMembers.map(item => item.id))
      setSelectedMembersIdsBeforeSave(staffMembersTarget.map(item => item.id))
      setLoading(false);
      toast.current.show({severity: 'success', summary: 'Succès', detail: 'Equipe affectée à l\'intervention avec succès.', life: 5000});

      // Send notification to ambulance team chief
      for (let member of staffMembersTarget) {
        if (member.groups.some(g => g.name === Groups.AMBULANCE_CHIEF.value)) {
          notificationSocket.send(
            JSON.stringify({
              "notif_type": NotificationType.INFO.value,
              "receiver": member.id,
              "target_id": interventionId,
              "level": NotificationLevel.HIGH,
              "description": (
                `Vous avez été ajouté comme chef d'amblance pour une intervention.`
              )
            })
          );
          break
        }
      }
    } catch (e) {
      console.log("-----e ", e)
      setLoading(false);
    }
  };

  const onChangeAmbulance = async (value) => {
    setLoading(true);
    try {
      // If there were a previous ambulance, delete it first.
      // FIXME: We don't need to do this if multiple ambulances mode is enabled.
      if (interventionAmbulanceId) {
        await update_intervention_ambulance(interventionAmbulanceId, {ambulance: value.id});
      }
      else {
        const response = await create_intervention_ambulance({
          intervention: interventionId,
          ambulance: value.id
        });
        setInterventionAmbulanceId(response.id)
      }
      setSelectedAmbulance(value)
      setLoading(false);
    } catch (e) {
      console.log("-----e ", e)
      setLoading(false);
    }
  };

  if (!stateIsReady) return <ProgressSpinner />

  return (
    <>
      <Toast ref={toast} />
      <h1 className='mt-10'>Choisir l'ambulance</h1>
      <Dropdown
        disabled={loading}
        value={selectedAmbulance}
        onChange={(e) => onChangeAmbulance(e.value)} options={availableAmbulances} optionLabel="identifier" 
        placeholder="Choisir une ambulance" className="w-full md:w-14rem mb-5 border border-gray-400 focus:border-primary focus:ring-primary" />

      {selectedAmbulance ? (
        <>
          <h1 className='mt-10'>Choisir les membres de l'équipe</h1>
          {/* <MultiSelect
            disabled={loading}
            value={selectedStaffMembers}
            onChange={(e) => setSelectedStaffMembers(e.value)}
            options={staffMembers}
            optionLabel="display" 
            placeholder="Selectionner les membres de l'équipe"
            maxSelectedLabels={3} className="w-full md:w-20rem" /> */}
            <PickList
              disabled={loading}
              source={staffMembersSource}
              target={staffMembersTarget}
              onChange={(e) => {
                setStaffMembersSource(e.source);
                setStaffMembersTarget(e.target);
                // setSelectedStaffMembers(e.target);
              }}
              itemTemplate={(item) => <div>{item.display}</div>}
              sourceHeader="Membres disponibles"
              targetHeader="Membres sélectionnés"
              showSourceControls={false}
              showTargetControls={false}
              className="w-full md:w-20rem" />
        </>
      ): null}
      <Button
        className="bg-primary text-white px-4 py-2 mt-5"
        label="Valider"
        onClick={save}
        loading={loading}
        disabled={loading && (!["ambulance-returned", "done", "white-out"].includes(interventionStatus))}
      />
    </>
  );
};

export default InterventionAmbulanceManager;
