import { InputText } from 'primereact/inputtext';

const CustomInputText = ({ ...props }) => {
    return (
        <InputText
            {...props}
            className="rounded focus:ring-primary focus:border-primary p-3 border border-gray-400"
        />
    );
}

export default CustomInputText